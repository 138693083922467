import React, { useState, useEffect, useContext } from "react";
import styles from "./CreateInterview.module.scss";
import {
  Box,
  Fab,
  IconButton,
  Paper,
  TextField,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Autocomplete,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardActionArea,
  CardContent,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
  Alert,
  SnackbarCloseReason,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IQuestion } from "../../models/IQuestion";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { callAPI, generateInterviewGPT } from "../../services/interview.service";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createData } from "../../utils/tableUtil";
import { useTranslation } from "react-i18next";
import DrawerWrapper from "../../components/DrawerWrapper/DrawerWrapper";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { QuestionStore } from "../../context/questionContext";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Menu from '@mui/material/Menu';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import questionImage from "../../assets/quest.png";
import messageImage from "../../assets/greeting.png";
import Result from "../Result/Result";
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';

function CreateInterview() {
  const [showQuestionField, setShowQuestionField] = useState(false);
  const [newQuestion, setNewQuestion] = useState<string>("");

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [interviewName, setInterviewName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [emails, setEmails] = useState<string[]>([]);
  const [emailInput, setEmailInput] = useState<string>("");
  const [existingInvitations, setExistingInvitations] = useState<any[]>([]);
  const [interviewUuid, setInterviewUuid] = useState<string | null>(null);
  const [existingQuestions, setExistingQuestions] = useState<IQuestion[]>([]);
  const [editQuestionId, setEditQuestionId] = useState<number | null>(null);
  const [editedQuestionText, setEditedQuestionText] = useState<string>("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedCard, setSelectedCard] = React.useState(0);
  const [checkedCcustom, setCheckedCcustom] = useState<boolean>(false);
  const [newQuestionText, setNewQuestionText] = useState("");
  const [isMandatory, setIsMandatory] = useState(false);
  const [requireAuth, setRequireAuth] = useState(false);
  const [welcomeTitle, setWelcomeTitle] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [completedTitle, setCompletedTitle] = useState("");
  const [completedMessage, setCompletedMessage] = useState("");
  const [openDialogConfirmation, setOpenDialogConfirmation] = React.useState(false)
  const [selectedCardRemove, setSelectedCardRemove] = React.useState(0);
  const [newInterview, setNewInterview] = useState<any>();
  const [existingQuestionIds, setExistingQuestionIds] = useState<number[]>([]);
  const [response, setResponse] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  











  const [question, setQuestion] = useState<string>("");

  const [open, setOpen] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const accessToken = useAuthToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const interviewId = searchParams.get("interviewId");
  const viewOnly = searchParams.get("viewOnly") === "true";
  const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";

  const steps = ["General Description", "Set Questions", "Send Invitations"];

  const [statusInterview, setStatusInterview] = React.useState<string>("");

  const [refinedPrompt, setRefinedPrompt] = useState<string>("");
  const [isRefining, setIsRefining] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    console.log("value", event.target.value);
    console.log("name", event.target.name);
    setStatusInterview(event.target.value);
  };

  const { t } = useTranslation();

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const fetchInterviews = async () => {
      if (interviewId) {
        try {
          const options = {
            accessToken,
            method: "GET",
            endpoint: `${endpoint}/interviews?All=yes`,
          };
          const response = await callAPI(options);
        

          const interview = response.find(
            (item: any) => item.Id.toString() === interviewId
          );

          if (interview) {
            console.log("interview", interview);
            setRequireAuth(interview.AuthOnly);
            setCompletedMessage(interview.CompletedMessage);
            setCompletedTitle(interview.CompletedTitle);
            setWelcomeTitle(interview.WelcomeTitle);
            setWelcomeMessage(interview.WelcomeMessage);
            setInterviewName(interview.Title);
            setDescription(interview.Description);
            setPrompt(interview.Prompt);
            setQuestions(interview.Questions || []);
            setEmails(interview.Invitations.map((invite: any) => invite.Email));
            setInterviewUuid(interview.Uuid);
            setExistingInvitations(interview.Invitations || []);
            setExistingQuestionIds(interview.Questions.map((question: IQuestion) => question.Id))
            setStatusInterview(interview.Status);
            setExistingQuestions(interview.Questions || []);


          }
        } catch (error) {
          console.error("Error fetching interviews:", error);
        }
      }
    };

    fetchInterviews();
  }, [interviewId, accessToken, endpoint]);




  const saveNewQuestion = () => {
    if (newQuestionText.trim()) {
      setQuestions((prevstate: any) => [
        ...prevstate,
        {
          Id:
            questions.reduce(
              (max, item) => (item.Id > max ? item.Id : max),
              0
            ) + 1,
          InterviewId: parseInt(interviewId || "0"),
          QuestionText: newQuestionText,
          QuestionOrder: prevstate.length + 1,
          IsRequired: isMandatory,
        },
      ]);
      setNewQuestionText("");
      setIsMandatory(false);
      setSelectedCard(100000001);
    }
  };

  const handleSaveEditedQuestion = (questionId: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.Id === questionId
          ? {
            ...question,
            QuestionText: editedQuestionText,
            IsRequired: isMandatory,
          }
          : question
      )
    );
    setEditQuestionId(null);
    setEditedQuestionText("");
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newQuestion.trim()) {
      const newQuestionObject: IQuestion = {
        Id: questions.length + 1,
        InterviewId: 1,
        QuestionText: newQuestion.trim(),
        QuestionOrder: questions.length + 1,
        IsRequired: true,
      };
      setQuestions([...questions, newQuestionObject]);
      setNewQuestion("");
      setShowQuestionField(false);
    }
  };

  const saveAllQuestions = async () => {
    try {
      for (const question of questions) {
        // Log the question ID and check if it's already saved
        console.log(`Checking question ID: ${question.Id}`);
        if (existingQuestionIds.includes(question.Id)) {
          console.log(`Skipping already saved question ID: ${question.Id}`);
          continue;
        }

        if (interviewId || newInterview?.Id) {
          const questionData: any = {
            InterviewId: interviewId && parseInt(interviewId) || newInterview?.Id,
            QuestionText: question.QuestionText,
            QuestionOrder: question.QuestionOrder,
            IsRequired: question.IsRequired,
          };

          const isExistingQuestion = existingQuestions.some(q => q.Id === question.Id);
          if (isExistingQuestion) {
            questionData.Id = question.Id;
          }

          const options = {
            accessToken,
            endpoint: `${endpoint}/question`,
            method: "PUT",
            body: questionData,
          };

          try {
            const response = await callAPI(options);
            console.log("Question saved:", response);

            // Assuming the response contains the saved question's ID
            if (response && response.Id) {
              // Mark this question as saved
              setExistingQuestionIds(prevIds => [...prevIds, response.Id]);
            }
          } catch (error) {
            console.error("Error saving question:", error);
          }
        }
      }

    } catch (error) {
      console.error("Error in saveAllQuestions:", error);
    }
  };

  const saveInvitations = async () => {
    //if (viewOnly) return;
    console.log("emails", emails)
    try {
      const emailList = Array.from(
        new Set(
          emails.map((email) => email.trim()).filter((email) => email !== "")
        )
      );

      if (emailList.length === 0) {
        console.log("No emails provided, skipping invitations step.");
        return;
      }

      const existingEmails = existingInvitations.map(
        (invite: any) => invite.Email
      );
      console.log("emailList", emailList)
      for (const email of emailList) {
        if (!existingEmails.includes(email)) {

          if (interviewId) {
            const invitationData = {
              InvitationStatus: "pending",
              InterviewId: parseInt(interviewId) || newInterview.Id,
              Email: email,
            };
            const options = {
              accessToken,
              endpoint: `${endpoint}/invitation`,
              method: "PUT",
              body: invitationData,
            };

            const response = await callAPI(options);
            console.log('invitationssss', response)
            const invitationCode = response.InvitationCode;

            if (requireAuth) {

              const link = `${window.location.origin}/${interviewUuid || newInterview.Uuid}`;

              const mailtoLink = `mailto:${email}?subject=Invitation%20to%20participate%20in%20a%20new%20Interview&body=Hi,%0A%0AYou%20have%20been%20invited%20to%20participate%20in%20an%20interview.%0A%0APlease%20use%20the%20following%20link%20to%20start:%0A%0A${link}%0A%0AThank%20you,%0AInterviewGPT%20Team`;


              window.location.href = mailtoLink;

            } else {

              const link = `${window.location.origin}/${interviewUuid || newInterview.Uuid}?invitationCode=${invitationCode}`;

              const mailtoLink = `mailto:${email}?subject=Invitation%20to%20participate%20in%20a%20new%20Interview&body=Hi,%0A%0AYou%20have%20been%20invited%20to%20participate%20in%20an%20interview.%0A%0APlease%20use%20the%20following%20link%20to%20start:%0A%0A${link}%0A%0AThank%20you,%0AInterviewGPT%20Team`;


              window.location.href = mailtoLink;


            }
          }
          else {
            const invitationData = {
              InvitationStatus: "pending",
              Email: email,
              InterviewId: newInterview.Id
            };
            const options = {
              accessToken,
              endpoint: `${endpoint}/invitation`,
              method: "PUT",
              body: invitationData,
            };

            const response = await callAPI(options);
            console.log(response, response)
          }




        }
      }

      for (const existingInvite of existingInvitations) {
        if (!emailList.includes(existingInvite.Email)) {
          await callAPI({
            accessToken,
            endpoint: `${endpoint}/invitation`,
            method: "DELETE",
            body: { Id: existingInvite.Id },
          });
          console.log(`Deleted invitation with Id: ${existingInvite.Id}`);
        }
      }
    } catch (error) {
      console.error("Error saving invitations:", error);
    }
  };

  const handleSaveInterview = async () => {
    //if (viewOnly) return;
    const interviewData: any = {
      Uuid: interviewId,
      Title: interviewName,
      Description: description,
      Prompt: prompt,
      Model: "gpt-4o",
      Status: "active",
    };
    if (interviewUuid) {
      interviewData.Uuid = interviewUuid;
    }
    try {
      const options = {
        accessToken,
        endpoint: `${endpoint}/interview`,
        method: "PUT",
        body: interviewData,
      };
      console.log("accessToken", accessToken);
      if (accessToken) {
        const response = await callAPI(options);

        const interviewId = response.Id;
        const interviewUuid = response.Uuid;

        console.log("response", response);

        /*  await saveAllQuestions(interviewId); */

        await saveInvitations();
        console.log("questions22", questions)
        navigate("/my-dashboard");
      }
    } catch (error) {
      console.error("Error saving interview:", error);
    }
  };

  // save in database
  const saveInterviewAnswersFunction = async (interviewData: any) => {
    console.log("interviewDatafucntion", interviewData)
    try {
      const options = {
        accessToken,
        endpoint: `${endpoint}/interview`,
        method: "PUT",
        body: interviewData,
      };

      if (accessToken) {
        const response = await callAPI(options);
        if (response) {
          setNewInterview(response)
          console.log("responsenewinterve", response)
        }
        handleNext();
      }
    } catch (error) {
      console.error("Error saving interview:", error);
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const saveAnswersByNext = async () => {
    if (activeStep === 0) {
      const interviewData: any = {
        Uuid: interviewUuid,
        Title: interviewName,
        Description: description,
        Prompt: prompt,
        Model: "gpt-4o",
        Status: "active",
      };
      saveInterviewAnswersFunction(interviewData);

    }
    if (activeStep === 1) {
      const interviewData: any = {
        Uuid: interviewUuid || newInterview?.Uuid,
        WelcomeMessage: welcomeMessage,
        WelcomeTitle: welcomeTitle,
        CompletedMessage: completedMessage ? completedMessage : "Thank you for taking the time to complete the interview! We appreciate your input and will review your responses carefully.",
        CompletedTitle: completedTitle ? completedTitle : "Thank You for Completing the Interview",
      };
      console.log(interviewData);
      saveInterviewAnswersFunction(interviewData);
      await saveAllQuestions()

    }

    if (activeStep === steps.length - 1) {
      if (interviewId !== null && interviewUuid || newInterview.Id !== null && newInterview?.Uuid) {

        const interviewData: any = {
          Uuid: interviewUuid || newInterview?.Uuid,
          status: statusInterview,
          AuthOnly: requireAuth
        };
        console.log('question and save', questions)
        saveInvitations();
        saveInterviewAnswersFunction(interviewData);
      } else {
        console.log('question and save and no id', questions)

        const interviewData: any = {
          Title: interviewName,
          Description: description,
          Prompt: prompt,
          Model: "gpt-4o",
          Status: "active",
          WelcomeMessage: welcomeMessage,
          WelcomeTitle: welcomeTitle,
          CompletedMessage: completedMessage,
          CompletedTitle: completedTitle,
          status: statusInterview,
          AuthOnly: requireAuth
        };

        saveInvitations()
        saveInterviewAnswersFunction(interviewData);
      }

      navigate("/my-dashboard")
      //setActiveStep(0);
      //setOpenAlert(true);
      //setTimeout(() => {
      //  navigate("/my-dashboard");
      //}, 4000);
    }

  };

  const handleEditQuestion = (questionId: any) => {
    if (!viewOnly) {
      const questionToEdit = questions.find((q) => q.Id === questionId);
      if (questionToEdit) {
        setEditQuestionId(questionId);
        setEditedQuestionText(questionToEdit.QuestionText);
      }
    }
  };

  const handleCancelEdit = () => {
    if (!viewOnly) {
      setEditQuestionId(null);
      setEditedQuestionText("");
    }
  };


  const handleChangeSwitch = () => {

    setCheckedCcustom(!checkedCcustom);
  };

  const handleCopyInvitationLink = (
    invitationCode: string,
    interviewUuid: string
  ) => {
    const invitationLink = `${window.location.origin}/public/${interviewUuid}?invitationCode=${invitationCode}`;
    navigator.clipboard.writeText(invitationLink);
    console.log(`Copied to clipboard: ${invitationLink}`);
  };

  const handleGoBack = () => {
    navigate("/my-dashboard");
  };

  const handleEditMode = () => {
    navigate(`/create-edit-interview?interviewId=${interviewId}`);
  };

  //steps
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  //steps

  const handlePrompt = async () => {
    if (!prompt.trim()) {
      return; // No ejecutar si el prompt está vacío
    }

    setOpenDialog(true);
    setIsRefining(true);
    setRefinedPrompt("");

    const refinementPrompt = `Refina y mejora el siguiente prompt para que sea más claro y conciso, manteniendo la esencia y el objetivo del mismo: "${prompt}"`;

    try {
      await generateInterviewGPT(
        refinementPrompt,
        (response: string) => {
          setRefinedPrompt(response);
        },
        setIsRefining
      );
    } catch (error) {
      console.error("Error refinando el prompt:", error);
      setIsRefining(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmAction = () => {
    setPrompt(refinedPrompt); // Actualizar el prompt original con el refinado
    setOpenDialog(false);
    setRefinedPrompt("");
  };

  const RequireAuthentication = () => {
    setRequireAuth(!requireAuth);
  };

  const handleCopyInvitationLinkAuth = () => {
    const invitationLink = `${window.location.origin}/${interviewUuid}`;
    navigator.clipboard.writeText(invitationLink);
    console.log(`Copied to clipboard: ${invitationLink}`);
  };

  const IsMandatory = (questionId: number) => {
    //guardar la pregunta
  };

  const addQuestion = () => {

    setSelectedCard(
      questions.reduce((max, item) => (item.Id > max ? item.Id : max), 0) + 1
    );
    console.log("se gusarda luego de crear la pegunta?,", questions)
    setQuestions(questions);
  };

  const handleRefineAgain = async () => {
    setIsRefining(true);
    setRefinedPrompt(""); // Limpiamos el prompt refinado anterior
    
    const refinementPrompt = `Refina y mejora el siguiente prompt para que sea más claro y conciso, manteniendo la esencia y el objetivo del mismo: "${prompt}"`;

    try {
      await generateInterviewGPT(
        refinementPrompt,
        (response: string) => {
          setRefinedPrompt(response); // Simplemente asignamos la nueva respuesta
        },
        setIsRefining
      );
    } catch (error) {
      console.error("Error refinando el prompt:", error);
      setIsRefining(false);
    }
  };



  const handleRemoveQuestion = async () => {

    setQuestions(questions.filter((question) => question.Id !== selectedCardRemove));

    const questionData = {
      "Id": selectedCardRemove
    }

    try {
      const options = {
        accessToken,
        endpoint: `${endpoint}/question`,
        method: "DELETE",
        body: questionData,
      };

      if (accessToken) {
        const response = await callAPI(options);
        console.log("responseremove", response)
      }
    } catch (error) {
      console.error("Error saving interview:", error);
    }
    setOpenDialogConfirmation(false)


  }

  const handleCloseDialogConfirmation = () => {
    setOpenDialogConfirmation(false)
  }




  const handleDragEnd = (result: any) => {
    if (!result.destination) return; // No cambio de posición

    const updatedQuestions = [...questions];
    const [reorderedItem] = updatedQuestions.splice(result.source.index, 1);
    updatedQuestions.splice(result.destination.index, 0, reorderedItem);

    // Actualizar el orden de cada pregunta
    const reorderedWithOrder = updatedQuestions.map((q, index) => ({
      ...q,
      order: index + 1,
    }));

    setQuestions(reorderedWithOrder);
  };








  return (
    <>
      <DrawerWrapper open={open} setOpen={setOpen} />
      <div style={{ width: open ? "80%" : "95%" }} className={styles.container}>
        <Stepper activeStep={activeStep} style={{ width: "80%" }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              /* labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              ); */
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === 0 && (
          <div className={styles.summary}>
            <div className={styles.createInterview}>
              <IconButton
                sx={{ position: "absolute", marginLeft: "-120px" }}
                onClick={handleGoBack}
              >
                <ArrowBackIcon />
              </IconButton>
              <h4 style={{ marginTop: "50px", marginBottom: "-20px" }}>
                General Description
              </h4>
              <TextField
                label={t("general.admin.interviewname")}
                variant="standard"
                fullWidth
                margin="normal"
                className={styles.textField}
                value={interviewName}
                onChange={(e) => setInterviewName(e.target.value)}
              />
              <TextField
                label={t("general.admin.description")}
                variant="standard"
                fullWidth
                margin="normal"
                multiline
                className={styles.textField}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <TextField
                label="Prompt"
                variant="standard"
                fullWidth
                margin="normal"
                multiline
                className={styles.textField}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <Button
                onClick={handlePrompt}
                variant="text"
                startIcon={<AutoAwesomeIcon />}
                style={{
                  backgroundColor: "transparent",
                  color: "#77a1d3",
                  padding: "10px 14px",
                  margin: "14px 0px",
                  width: "160px",
                }}
              >
                {"Refine Prompt"}
              </Button>
              <TextField
                variant="standard"
                fullWidth
                margin="normal"
                multiline
                className={`${styles.textField} ${styles.AIModel}`}
                value={"GPT-4o"}
              />
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className={styles.questions}>
            <div style={{ width: "40%" }}>
              {/*   <h4 style={{ marginTop: "50px", marginBottom: "-20px" }}>General Description</h4> */}
              <Typography variant="h6" className={styles.title}>
                {t("general.admin.questions")}
              </Typography>
              <p>Total: {questions.length} questions</p>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gap: 2,
                }}
              >
                <Card key={100000000}>
                  <CardActionArea
                    onClick={() => setSelectedCard(100000000)}
                    data-active={selectedCard === 100000000 ? "" : undefined}
                    sx={{
                      height: "100%",
                      border: "1px solid #77a2d3",
                      "&[data-active]": {
                        backgroundColor: "action.selected",
                        "&:hover": {
                          backgroundColor: "action.selectedHover",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        gap: 2,
                        padding: "6px",
                      }}
                    >
                      {/* <LogoutIcon /> */} {/* Your icon */}
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MarkUnreadChatAltRoundedIcon/>
                        <div style={{ marginLeft: "6px" }}>
                          <Typography variant="body2" component="div">
                            {"Hello"}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {"Home Screen"}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </CardActionArea>
                </Card>
                <br></br>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="questionsList">
                    {(provided: any) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{ width: "100%", margin: "auto", position: "relative" }}
                      >

                        {questions.map((question, index) => (
                          <Draggable key={question.Id} draggableId={question.Id.toString()} index={index}>
                            {(provided: any, snapshot: any) => (
                              <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={index}
                                onClick={() => setSelectedCard(question.Id)}
                                data-active={
                                  selectedCard === question.Id ? "" : undefined
                                }
                                sx={{
                                  position: "relative", // Ensure relative position for stacking
                                  height: "100%",
                                  border: "1px solid #77a2d3",
                                  "&[data-active]": {
                                    backgroundColor: "action.selected",

                                    "&:hover": {
                                      backgroundColor: "action.selectedHover",
                                    },
                                  },
                                  marginBottom: 2,
                                  padding: 1,
                                  boxShadow: snapshot.isDragging ? 6 : 2,
                                  backgroundColor: snapshot.isDragging ? "#f0f0f0" : "white",
                                  transition: "background-color 0.2s",
                                  cursor: "grab",
                                }}>

                                <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}>
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setAnchorEl(event.currentTarget);
                                      setSelectedCardRemove(question.Id);
                                    }}
                                    sx={{ zIndex: 10000, backgroundColor: "transparent" }}
                                    size="small"
                                    color="inherit"
                                  >
                                    <MoreVertRoundedIcon />
                                  </IconButton>
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',

                                    }}

                                  >
                                    <MenuItem onClick={() => {
                                      setOpenDialogConfirmation(true);
                                      setAnchorEl(null);

                                    }}>
                                      <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                                      Remove
                                    </MenuItem>

                                  </Menu>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    height: "100%",
                                    width: "100%",
                                    alignItems: "center",
                                    gap: 2,
                                    padding: "6px",
                                  }}
                                >
                                  {/* <LogoutIcon /> */} {/* Your icon */}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      marginRight: "10px"
                                    }}
                                  >
                                    < ArticleRoundedIcon/>
                                    <div style={{ marginLeft: "6px" }}>
                                      <Typography variant="body2" component="div">
                                        {question.QuestionText}
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary">
                                        {"Audio response"}
                                      </Typography>
                                    </div>
                                  </div>
                                </Box>

                              </Card>
                            )}
                          </Draggable>
                        ))}


                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>





                <div className={styles.column}>
                  <Box className={styles.questionContainer}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        minWidth: "100px",
                        borderRadius: "20px",
                      }}
                      onClick={addQuestion}
                    >
                      {t("general.admin.addQuestion")}
                    </Button>
                  </Box>
                </div>
                <Card key={100000002}>
                  <CardActionArea
                    onClick={() => setSelectedCard(100000002)}
                    data-active={selectedCard === 100000002 ? "" : undefined}
                    sx={{
                      height: "100%",
                      border: "1px solid #77a2d3",
                      "&[data-active]": {
                        backgroundColor: "action.selected",

                        "&:hover": {
                          backgroundColor: "action.selectedHover",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        gap: 2,
                        padding: "6px",
                      }}
                    >
                      {/* <LogoutIcon /> */} {/* Your icon */}
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                         <MarkUnreadChatAltRoundedIcon/>
                        <div style={{ marginLeft: "6px" }}>
                          <Typography variant="body2" component="div">
                            {"Thank you!"}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {"Thank you screen"}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </CardActionArea>
                </Card>
              </Box>
            </div>
            <div style={{ width: "60%", padding: "40px" }}>
              {selectedCard === 100000000 && (
                <div className={styles.createInterview}>
                  <h2>Welcome Screen</h2>
                  <TextField
                    label={"Title"}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    className={styles.textField}
                    value={welcomeTitle}
                    onChange={(e) => setWelcomeTitle(e.target.value)}
                  />
                  <TextField
                    label={"Message"}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    className={styles.textField}
                    value={welcomeMessage}
                    onChange={(e) => setWelcomeMessage(e.target.value)}
                  />
                </div>
              )}
              {questions.map(
                (question, index) =>
                  question.Id === selectedCard && (
                    <div className={styles.createInterview} key={question.Id}>
                      <h2>{"Question"}</h2>
                      <TextField
                        //id={`${question.Id}`}
                        label={"Question"}
                        variant="standard"
                        fullWidth
                        margin="normal"
                        className={styles.textField}
                        value={question.QuestionText}
                        onChange={(e) => {
                          // Update the QuestionText of the correct question based on its Id
                          const updatedQuestions = [...questions];
                          updatedQuestions[index] = {
                            ...updatedQuestions[index],
                            QuestionText: e.target.value,
                          };
                          setQuestions(updatedQuestions); // Assuming setQuestions updates the full array
                        }}
                      />
                      <FormGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "40px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginLeft: "6px" }}>
                          <Typography variant="body2" component="div">
                            {"Required"}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {"Required question"}
                          </Typography>
                        </div>
                        <Switch
                          onChange={(e) => {
                            // Update the mandatory state for the correct question based on its Id
                            const updatedQuestions = [...questions];
                            updatedQuestions[index] = {
                              ...updatedQuestions[index],
                              IsRequired: e.target.checked,
                            };
                            setQuestions(updatedQuestions);
                          }}
                          checked={question.IsRequired}
                        />
                      </FormGroup>
                    </div>
                  )
              )}
              {selectedCard ===
                questions.reduce(
                  (max, item) => (item.Id > max ? item.Id : max),
                  0
                ) +
                1 && (
                  <div className={styles.createInterview}>
                    <h2>Question</h2>
                    <TextField
                      label={"Question"}
                      variant="standard"
                      fullWidth
                      margin="normal"
                      className={styles.textField}
                      value={newQuestionText}
                      onChange={(e) => setNewQuestionText(e.target.value)}
                    />
                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "40px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginLeft: "6px" }}>
                        <Typography variant="body2" component="div">
                          {"Required"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {"Required question"}
                        </Typography>
                      </div>
                      <Switch
                        checked={isMandatory}
                        onChange={(e) => setIsMandatory(e.target.checked)}
                      />
                    </FormGroup>
                    <div
                      style={{
                        display: "flex",
                        padding: "9px",
                        justifyContent: "center",
                        marginTop: "24px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setSelectedCard(100000001)}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={saveNewQuestion}>
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              {selectedCard === 100000001 && <></>}
              {selectedCard === 100000002 && (
                <div className={styles.createInterview}>
                  <h2>Thank you Screen</h2>
                  <FormGroup
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "40px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ marginLeft: "6px" }}>
                      <Typography variant="body2" component="div">
                        {"Custom message"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {"Optional message"}
                      </Typography>
                    </div>

                    <Switch
                      checked={completedTitle && completedTitle.length > 0 || completedMessage && completedMessage.length > 0 || checkedCcustom}
                      onChange={(e) => {
                        setCheckedCcustom(e.target.checked);
                        if (!e.target.checked) {
                          setCompletedTitle('');
                          setCompletedMessage('');
                        }
                      }}
                    />
                    {(completedTitle && completedTitle.length > 0 || completedMessage && completedMessage.length > 0 || checkedCcustom) && (
                      <>
                        <TextField
                          label={"Title"}
                          variant="standard"
                          fullWidth
                          margin="normal"
                          className={styles.textField}
                          value={completedTitle}
                          onChange={(e) => setCompletedTitle(e.target.value)}
                        />
                        <TextField
                          label={"Message"}
                          variant="standard"
                          fullWidth
                          margin="normal"
                          className={styles.textField}
                          value={completedMessage}
                          onChange={(e) => setCompletedMessage(e.target.value)}
                        />
                      </>
                    )}
                  </FormGroup>
                </div>
              )}
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div style={{ marginTop: "1.2rem", width: "60%", padding: "30px" }}>
            {/*   <h4 style={{ marginTop: "50px", marginBottom: "-20px" }}>General Description</h4> */}
            <Typography variant="h6" className={styles.title}>
              {"Invitations"}
            </Typography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginLeft: "6px" }}>
                <Typography variant="body2" component="div">
                  {"Require authentication"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {"Users require authentication to enter to the interview"}
                </Typography>
              </div>
              <Switch checked={requireAuth} onChange={RequireAuthentication}/>
            </FormGroup>

            <FormControl variant="standard" sx={{ m: 1, width: "100%", marginTop:"40px" }}>
           
            <Typography variant="body2" component="div">
                  {"Status"}
                </Typography>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={statusInterview}
                onChange={handleChangeStatus}
                label="Status"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"active"}>active</MenuItem>
                <MenuItem value={"inactive"}>inactive</MenuItem>
              </Select>
        
            </FormControl>
            {/*   <h4 style={{ marginTop: "50px", marginBottom: "-20px" }}>General Description</h4> */}
            <Typography
              variant="h6"
              className={styles.title}
              sx={{ marginTop: "50px" }}
            >
              {"Send Invitations:"}
            </Typography>
            <div
              style={{
                marginTop: "20px",
                marginLeft: "6px" 
              }}
              className={styles.stepContainer}
            >
              <div className={styles.column} >
              <Typography variant="body2" component="div" sx={{marginBottom:"10px"}}>
              {t("general.admin.invitationEmail")}
                </Typography>
                <Box className={styles.questionContainer} >
                  <Autocomplete
                    multiple
                    //disableClearable={viewOnly}

                    options={[]}
                    fullWidth
                    freeSolo
                    limitTags={3}
                    getOptionLabel={(option) => option}
                    value={emails}
                    onChange={(event: any, newValue: string[], reason) => {
                      setEmails(newValue);
                    }}
                    inputValue={emailInput}
                    onInputChange={(event, newInputValue) => {
                      setEmailInput(newInputValue);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setEmails([...emails, emailInput]);
                        setEmailInput("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("general.admin.email")} //!viewOnly ? t("general.admin.email") : ""}
                      />
                    )}
                  />
                </Box>
              </div>
            </div>
            <br />
            {requireAuth ? (
              interviewId ? (
                <Box className={styles.questionContainer}>
                  <p>
                    {window.location.origin}/{interviewUuid}
                  </p>
                  <Tooltip title="Copy invitation link">
                    <IconButton
                      onClick={() => handleCopyInvitationLinkAuth()}
                      size="small"
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : null
            ) : interviewId ? (
              <TableContainer component={Paper} sx={{marginLeft: "6px"}} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">
                        {" "}
                        {t("general.admin.status")}
                      </TableCell>
                      <TableCell align="right">Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {existingInvitations.map((invitation, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {invitation.Email}
                        </TableCell>
                        <TableCell align="right">
                          {invitation.InvitationStatus}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Copy invitation link">
                            <IconButton
                              onClick={() =>
                                handleCopyInvitationLink(
                                  invitation.InvitationCode,
                                  interviewUuid || ""
                                )
                              }
                              size="small"
                            >
                              <ContentCopyIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            ></div>


          </div>
        )}
        {activeStep === steps.length ? (
          <React.Fragment>

          </React.Fragment>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
              style={{ display: "flex", padding: "10px 50px" }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button
                color="inherit"
                onClick={handleSkip}
                sx={{ mr: 1 }}
                style={{
                  backgroundColor: "transparent",
                  color: "#27496b",
                  display: "flex",
                  padding: "10px",
                }}
              >
                Skip
              </Button>
            )}
            <Button
              onClick={saveAnswersByNext}
              variant="contained"
              style={{ display: "flex", padding: "10px 50px" }}
            >
              {activeStep === steps.length - 1 ? "Save & Finish" : "Next"}
            </Button>
          </Box>
        )}
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Your interview was saved successfully!
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title" textAlign="center">
          {"Refine prompt with AI"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isRefining ? (
              <CircularProgress size={24} />
            ) : (
              refinedPrompt || "No refined prompt generated yet"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRefineAgain}
            color="secondary"
            variant="outlined"
            disabled={isRefining}
          >
            {"Refine again"}
          </Button>
          <Button
            onClick={handleConfirmAction}
            color="primary"
            variant="contained"
            autoFocus
            disabled={isRefining || !refinedPrompt}
          >
            {"Use prompt"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDialogConfirmation}
        onClose={handleCloseDialogConfirmation}
        onConfirm={handleRemoveQuestion}
        message={"Are you sure of deleting this question?"}
      />
    </>
  );
}

export default CreateInterview;
