import React from "react";
import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IInterview, IQuestion } from "../models/IQuestion";
import { callAPI } from "../services/interview.service";
import { useAuthToken } from "../hooks/useAuthToken";

export const QuestionStore = createContext<{
  interview: IInterview | null;
  questions: IQuestion[];
  setQuestions: (questions: IQuestion[]) => void;
  loading: boolean;
  sessionId: number;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: (index: number) => void;
  currentQuestion: IQuestion | null;
  setCurrentQuestion: (question: IQuestion) => void;
  welcomeTitle: string | null;
  welcomeMessage: string | null;
  completedTitle: string | null;
  completedMessage: string | null;
}>({
  interview: null,
  questions: [],
  setQuestions: () => {},
  loading: false,
  sessionId: 0,
  currentQuestionIndex: 0,
  setCurrentQuestionIndex: () => {},
  currentQuestion: null,
  setCurrentQuestion: () => {},
  welcomeTitle: null,
  welcomeMessage: null,
  completedTitle: null,
  completedMessage: null,
});

QuestionStore.displayName = "QuestionContext";

function QuestionContext({ children }: { children: React.ReactNode }) {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<number>(0);
  const [interview, setInterview] = useState<IInterview | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<IQuestion | null>(
    null
  );
  const [welcomeTitle, setWelcomeTitle] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState(null);
  const [completedTitle, setCompletedTitle] = useState(null);
  const [completedMessage, setCompletedMessage] = useState(null);

  const [searchParams] = useSearchParams();
  const accessToken = useAuthToken();

  const invitationCode = searchParams.get("invitationCode");
  const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";
  const location = useLocation();
  const uuidMatch = location.pathname.match(/([a-f0-9\-]+)/);
  const uuid = uuidMatch && uuidMatch[1];

  const questionNumMatch = location.pathname.match(/\/questions\/(\d+)/);
  const questionId = questionNumMatch
    ? questionNumMatch[1]
    : "Number not found";

  useEffect(() => {
    const fetchQuestionsInvitationCode = async () => {
      setLoading(true);
      const response = await callAPI({
        method: "GET",
        endpoint: `${endpoint}/public/interview?Uuid=${uuid}&InvitationCode=${invitationCode}`,
      });
      if (response) {
        setInterview(response.interview);
        setQuestions(response.interview.Questions);
        setSessionId(response.session.Id);
        setCurrentQuestionIndex(parseInt(questionId || "") - 1);
        setCurrentQuestion(
          response.interview.Questions[parseInt(questionId || "") - 1]
        );
        setWelcomeMessage(response.interview.WelcomeMessage);
        setWelcomeTitle(response.interview.WelcomeTitle);
        setCompletedMessage(response.interview.CompletedMessage);
        setCompletedTitle(response.interview.CompletedTitle);

        setLoading(false);
      }
    };

    const fetchQuestionsAccessToken = async () => {
      setLoading(true);
      const response = await callAPI({
        accessToken,
        method: "GET",
        endpoint: `${endpoint}/interview?Uuid=${uuid}&Admin=false`,
      });
      if (response) {
        setInterview(response.interview);
        setQuestions(response.interview.Questions);
        setSessionId(response.session.Id);
        setCurrentQuestionIndex(parseInt(questionId || "") - 1);
        setCurrentQuestion(
          response.interview.Questions[parseInt(questionId || "") - 1]
        );
        setWelcomeMessage(response.interview.WelcomeMessage);
        setWelcomeTitle(response.interview.WelcomeTitle);
        setCompletedMessage(response.interview.CompletedMessage);
        setCompletedTitle(response.interview.CompletedTitle);
        setLoading(false);
      }
    };
    if (invitationCode && uuid) {
      fetchQuestionsInvitationCode();
    }
    if (!invitationCode && accessToken && uuid && uuid.length > 10) {
      fetchQuestionsAccessToken();
    }
  }, [uuid, questionId, accessToken, invitationCode]);

  return (
    <QuestionStore.Provider
      value={{
        interview,
        questions,
        setQuestions,
        loading,
        sessionId,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        currentQuestion,
        setCurrentQuestion,
        welcomeTitle,
        welcomeMessage,
        completedTitle,
        completedMessage,
      }}
    >
      {children}
    </QuestionStore.Provider>
  );
}

export default QuestionContext;
