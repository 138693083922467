import { SvgIcon } from "@mui/material";
import React from "react";

const FeedbackIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 15.75C3.5375 15.75 3.35938 15.6781 3.21563 15.5344C3.07188 15.3906 3 15.2125 3 15V12C3 11.5875 3.14687 11.2344 3.44063 10.9406C3.73438 10.6469 4.0875 10.5 4.5 10.5H13.5C13.9125 10.5 14.2656 10.6469 14.5594 10.9406C14.8531 11.2344 15 11.5875 15 12V15C15 15.2125 14.9281 15.3906 14.7844 15.5344C14.6406 15.6781 14.4625 15.75 14.25 15.75H3.75ZM6.75 9.75C5.7125 9.75 4.82813 9.38438 4.09688 8.65313C3.36563 7.92188 3 7.0375 3 6C3 4.9625 3.36563 4.07813 4.09688 3.34688C4.82813 2.61563 5.7125 2.25 6.75 2.25H11.25C12.2875 2.25 13.1719 2.61563 13.9031 3.34688C14.6344 4.07813 15 4.9625 15 6C15 7.0375 14.6344 7.92188 13.9031 8.65313C13.1719 9.38438 12.2875 9.75 11.25 9.75H6.75ZM6.75 6.75C6.9625 6.75 7.14062 6.67813 7.28438 6.53438C7.42813 6.39062 7.5 6.2125 7.5 6C7.5 5.7875 7.42813 5.60938 7.28438 5.46563C7.14062 5.32188 6.9625 5.25 6.75 5.25C6.5375 5.25 6.35938 5.32188 6.21563 5.46563C6.07188 5.60938 6 5.7875 6 6C6 6.2125 6.07188 6.39062 6.21563 6.53438C6.35938 6.67813 6.5375 6.75 6.75 6.75ZM11.25 6.75C11.4625 6.75 11.6406 6.67813 11.7844 6.53438C11.9281 6.39062 12 6.2125 12 6C12 5.7875 11.9281 5.60938 11.7844 5.46563C11.6406 5.32188 11.4625 5.25 11.25 5.25C11.0375 5.25 10.8594 5.32188 10.7156 5.46563C10.5719 5.60938 10.5 5.7875 10.5 6C10.5 6.2125 10.5719 6.39062 10.7156 6.53438C10.8594 6.67813 11.0375 6.75 11.25 6.75Z"
          fill="#7FA3D5"
        />
      </svg>
    </SvgIcon>
  );
};

export default FeedbackIcon;
