import React, { useEffect, useState } from "react";
import styles from "./Resultinterview.module.scss";
import { useAuthToken } from "../../hooks/useAuthToken";
import DrawerWrapper from "../../components/DrawerWrapper/DrawerWrapper";
import { Box, Button, Card, CardActionArea, CardContent, Checkbox, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import SpaceDashboardTwoToneIcon from '@mui/icons-material/SpaceDashboardTwoTone';

import LogoutIcon from "@mui/icons-material/Logout";
import HeadTableComponent from "../../components/HeadTableComponent";
import ChatInterview from "../ChatInterview/ChatInterview";
import SummaryInterview from "../SummaryInterview/SummaryInterview";

import { callAPI } from "../../services/interview.service";
import { IInterview, IInterviewApi2, IResponse } from "../../models/IQuestion";
import { PieChart } from '@mui/x-charts/PieChart';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';

interface responseInterview {
    Id: number,
    SessionId: number,
    InterviewQuestionId: number,
    ResponseText: string,
    CreatedAt: string,
    UpdatedAt: string
}


function ResultInterview() {

    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const interviewId = searchParams.get("interviewId");
    const [value, setValue] = React.useState(0);
    const [selectedCard, setSelectedCard] = React.useState(0);
    const accessToken = useAuthToken();
    const [respuestaInterview, setRespuestaInterview] = useState<any[]>([]);
    const [interview, setInterview] = React.useState<IInterviewApi2>();
    const [responsesUser, setResponsesUser] = React.useState<number>(0)


    const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");


    const handleSort = () => {
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    };


    const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }


    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    //invitations list

    useEffect(() => {
        const fetchInterviews = async () => {
            console.log("accessTokenmotiv", accessToken)
            if (interviewId && accessToken) {
                try {
                    const options = {
                        accessToken,
                        method: "GET",
                        endpoint: `${endpoint}/interviews?All=yes`,
                    };
                    const response = await callAPI(options);
                    console.log("response", response);

                    if (response) {
                        const interview = response.find(
                            (item: any) => item.Id.toString() === interviewId
                        );

                        setInterview(interview)

                        const optionInterview = {
                            accessToken,
                            method: "GET",
                            endpoint: `${endpoint}/interview?Admin=true&Uuid=${interview.Uuid}`
                        }
                        const onlyInterview = await callAPI(optionInterview);
                        console.log('interviewonlyonly', onlyInterview.Sessions)

                        const responsesByInterview = onlyInterview.Sessions.flatMap((session:any) =>
                            session.Responses.map((response:any) => ({
                                User: session.SessionUser,
                                Id: response.Id,
                                SessionId: response.SessionId,
                                QuestionId: response.InterviewQuestionId,
                                ResponseText: response.ResponseText,
                                CreatedAt: response.CreatedAt,
                                UpdatedAt: response.UpdatedAt
                            }))
                        );
                        setRespuestaInterview(responsesByInterview)

                        const userCount = new Set(
                            onlyInterview.Sessions
                                .filter((session:any) => session.SessionUser) // Ensure the session has a user
                                .map((session:any) => session.SessionUser) // Extract unique users
                        ).size;
                        setResponsesUser(userCount)




                    }



                } catch (error) {
                    console.error("Error fetching interviews:", error);
                }
            }
        };

        fetchInterviews();
    }, [interviewId, accessToken, endpoint]);


    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }





    return (
        <>
            <DrawerWrapper open={open} setOpen={setOpen} />
            <div style={{ width: open ? "80%" : "95%" }} className={styles.container}>
                <div className={styles.summary}>
                    <div className={styles.summarygroup}>
                        <div className={styles.summarycount}>
                            <Typography
                                variant="h2"
                                sx={{
                                    mb: "15px",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    display: "inline-block",
                                    paddingBottom: "5px"
                                }}
                            >
                                {interview ? `Interview Results: ${interview.Title}` : "Interview Results"}
                            </Typography>

                            {/* Subtitle with Additional Info */}
                            {interview && (
                                <>
                                    <Typography variant="subtitle1" sx={{ fontStyle: "italic", mt: 1 }}>
                                        {`"${interview.Title}" - Analyzed Results`}
                                    </Typography>

                                    <Typography variant="body1" sx={{ mt: 1, display: "flex", alignItems: "center", }}>
                                        <CalendarMonthRoundedIcon /><strong style={{ marginLeft: "5px" }}>Created On:</strong> {new Date(interview.CreatedAt).toLocaleDateString()}
                                    </Typography>

                                    <Typography variant="body1" sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                                        <PeopleAltRoundedIcon /><strong style={{ marginLeft: "5px" }}>Invitations:</strong> {interview.Invitations.length}
                                    </Typography>
                                </>
                            )}
                        </div>

                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab iconPosition="start" icon={<SpaceDashboardTwoToneIcon />} label="General" {...a11yProps(0)} />
                                <Tab iconPosition="start" icon={<ArticleRoundedIcon />} label="Summary" {...a11yProps(1)} />
                                <Tab iconPosition="start" icon={<AutoAwesomeTwoToneIcon />} label="Chat with answers" ></Tab>
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Box sx={{ padding: 3 }}>
                                <Grid container spacing={3}>
                                    {/* Interview Details */}
                                    <Grid item xs={12} md={6}>
                                        <Paper elevation={3} sx={{ padding: 3 }}>
                                            <Typography variant="h5" gutterBottom>
                                                Interview Details
                                            </Typography>
                                            <Box sx={{ padding: 2, backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
                                                {[
                                                    { label: "Interview ID", value: interviewId },
                                                    { label: "Description", value: interview?.Description },
                                                    { label: "Prompt", value: interview?.Prompt },
                                                    { label: "Number of Questions", value: interview?.Questions.length },
                                                    { label: "Date of Creation", value: interview?.CreatedAt },
                                                    { label: "Created by", value: interview?.CreatedBy },

                                                ].map((item, idx) => (
                                                    <Typography key={idx} variant="body1">
                                                        <strong>{item.label}:</strong> {item.value}
                                                    </Typography>
                                                ))}
                                            </Box>

                                        </Paper>
                                        <br></br>
                                        <Paper elevation={3} sx={{ padding: 3 }}>
                                            {/* Title */}
                                            <Typography variant="h5" gutterBottom>
                                                Questions Details
                                            </Typography>

                                            {/* Stack for Proper Layout */}
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                {interview?.Questions.map((item, idx) => (
                                                    <Box key={idx} sx={{ padding: 2, backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
                                                        <Typography variant="body1">
                                                            <strong>Question ID:</strong> {item.Id}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            <strong>Question:</strong> {item.QuestionText}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            <strong>Required:</strong> {item.IsRequired ? "Yes" : "No"}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                        <br></br>
                                        <Paper elevation={3} sx={{ padding: 3 }}>
                                            {/* Title */}
                                            <Typography variant="h5" gutterBottom>
                                                Invitations Details
                                            </Typography>

                                            {/* Stack for Proper Layout */}
                                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                {interview?.Invitations.map((item, idx) => (
                                                    <Box key={idx} sx={{ padding: 2, backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
                                                        <Typography variant="body1">
                                                            <strong>Invitation ID:</strong> {item.Id}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            <strong>Email:</strong> {item.Email}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            <strong>Status:</strong> {item.InvitationStatus}
                                                        </Typography>


                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>

                                    </Grid>

                                    {/* Pie Chart Section */}
                                    <Grid item xs={12} md={6}>
                                        <Paper elevation={3} sx={{ padding: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            {/* Title for the Chart */}
                                            <Typography
                                                variant="h6"
                                                sx={{ fontWeight: "bold", marginBottom: 2, textAlign: "center" }}
                                            >
                                                Answered vs. Awaiting response
                                            </Typography>

                                            {/* Pie Chart */}
                                            <PieChart
                                                sx={{ maxWidth: 400 }}
                                                series={[
                                                    {
                                                        data: [
                                                            {
                                                                id: 0,
                                                                value: responsesUser || 0,
                                                                label: `${responsesUser || 0} Answered `
                                                            },
                                                            {
                                                                id: 1,
                                                                value: (interview?.Invitations.length || 0) - (responsesUser || 0),
                                                                label: `${(interview?.Invitations.length || 0) - (responsesUser || 0)} Awaiting response`
                                                            },
                                                        ],
                                                        innerRadius: 50, // Adds a donut effect for clarity
                                                        outerRadius: 100,
                                                        paddingAngle: 3, // Adds spacing between slices for better readability
                                                        cx: "35%", // Centers the pie chart horizontally
                                                        cy: "50%", // Centers the pie chart vertically
                                                    },
                                                ]}
                                                width={400}
                                                height={300}
                                                slotProps={{
                                                    legend: {
                                                        direction: "column", // Stacks legend items vertically
                                                        position: { vertical: "middle", horizontal: "right" }, // Places legend on the right
                                                        padding: 10,
                                                        itemGap: 10, // Space between legend items
                                                        labelStyle: { fontSize: 14, fontWeight: "bold" }
                                                    },
                                                }}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>

                                <br />

                                {/* Improved Table */}
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {[
                                                    "User",
                                                    "Response ID",
                                                    "Session ID",
                                                    "Response",
                                                    "Created At",
                                                    "Updated At",
                                                ].map((head, idx) => (
                                                    <TableCell key={idx}>
                                                        <TableSortLabel active={idx === 0} direction={orderDirection} onClick={handleSort}>
                                                            {head}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {respuestaInterview.map((response, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{response.User}</TableCell>
                                                    <TableCell>{response.Id}</TableCell>
                                                    <TableCell>{response.SessionId}</TableCell>
                                                    <TableCell>{response.ResponseText}</TableCell>
                                                    <TableCell>
                                                        {new Intl.DateTimeFormat("en-US", {
                                                            year: "numeric",
                                                            month: "short",
                                                            day: "2-digit",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                        }).format(new Date(response.CreatedAt))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Intl.DateTimeFormat("en-US", {
                                                            year: "numeric",
                                                            month: "short",
                                                            day: "2-digit",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                        }).format(new Date(response.UpdatedAt))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <SummaryInterview />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <ChatInterview />
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>

        </>
    )
}
export default ResultInterview; 
