import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
//import { useIsAuthenticated } from "@azure/msal-react";

const ProtectedRoute: React.FC = () => {
  const { accounts, instance } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  //const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    const checkAccount = async () => {
      if (!isAuthenticated) {
        try {
          await instance.handleRedirectPromise();
          const currentAccounts = instance.getAllAccounts();
          if (currentAccounts.length === 0) {
            instance.loginRedirect({
              redirectUri: location.pathname,
              scopes: [],
            });
          }
        } catch (error) {
          console.error("Error handling redirect:", error);
        }
      }
      setLoading(false);
    };
    checkAccount();
  }, [isAuthenticated, instance, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="" />;
};

export default ProtectedRoute;
