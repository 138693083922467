import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./i18n";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalInstance } from "./authConfig";

import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import store from "./store/store";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
