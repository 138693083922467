import { Box, Button, Snackbar, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Home.module.scss";
import AnimationComponent from "../../components/AnimationComponent/AnimationComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { callAPI } from "../../services/interview.service";
import { useTranslation } from "react-i18next";
import { Login } from "@mui/icons-material";
import UpperBar from "../../components/UpperBar";
import { AppRoutesProps, HomeRoutesProps } from "../../routes/AppRoutes";
import LoginMenu from "../Login/LoginMenu";

const Home: FC<HomeRoutesProps> = ({
  hideQuestions,
  darkMode,
  setDarkMode,
  publicMode,
}) => {
  const navigate = useNavigate();
  // const uuid = useSelector((state: RootState) => state.uuidData.uuid);
  const [searchParams] = useSearchParams();
  const uuidMatch = location.pathname.match(/([a-f0-9\-]+)$/);
  const uuid = uuidMatch && uuidMatch[1];
  const invitationCode = searchParams.get("invitationCode");

  // Añadir useEffect para redirección automática
  useEffect(() => {
    if (invitationCode) {
      if (publicMode) {
        navigate(`/public/${uuid}/introduction?invitationCode=${invitationCode}`);
      } else {
        navigate(`/${uuid}/introduction?invitationCode=${invitationCode}`);
      }
    }
  }, [invitationCode, uuid, navigate, publicMode]);

  const [invitationcodevalue, setInvitationcodevalue] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const { t } = useTranslation();
  const startInterview = () => {
    if (invitationCode === null) {
      navigate(`/${uuid}/introduction?invitationCode=${invitationcodevalue}`);
      setOpenSnackbar(true);
    } else {
      navigate(`/${uuid}/introduction?invitationCode=${invitationCode}`);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const style = "right";
  return (
    <>
      <UpperBar
        hideQuestions={hideQuestions}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <div className={styles.container}>
        <div className={styles.container_center}>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message="Note archived"
          />
          <Box
            sx={{
              paddingTop: "12px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {publicMode ? (
              invitationCode !== null ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className={styles.textWithBlur}>
                    <h1> {t("general.welcome")}</h1>
                    <p>{t("general.welcomemessage")}</p>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={startInterview}
                  >
                    {t("general.buttontobegin")}
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className={styles.textWithBlur}>
                    <h1> {t("general.welcome")}</h1>
                    <p>{t("general.welcomemessage")}</p>
                  </div>
                  <TextField
                    placeholder="Enter invitation code"
                    value={invitationcodevalue}
                    onChange={(e) => setInvitationcodevalue(e.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      sx: {
                        textAlignLast: "center",
                        "&::placeholder": {
                          textAlignLast: "center",
                        },
                      },
                    }}
                  />
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={startInterview}
                    disabled={!invitationcodevalue}
                    sx={{
                      backgroundColor: invitationcodevalue
                        ? "primary.main"
                        : "lightgray",
                      "&:hover": {
                        backgroundColor: invitationcodevalue
                          ? "primary.dark"
                          : "lightgray",
                      },
                    }}
                  >
                    {t("general.buttontobegin")}
                  </Button>
                </div>
              )
            ) : (
              <LoginMenu />
            )}
          </Box>
        </div>
        <AnimationComponent></AnimationComponent>
      </div>
    </>
  );
};

export default Home;
