import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Result.module.scss";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Rating,
  DialogActions,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { IInterviewState } from "../../models/IInterviewState";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  generateInterviewGPT,
  generateResultGPT,
} from "../../services/interview.service";
import { callAPI } from "../../services/interview.service";
import confeti from "../../assets/confeti.png";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import prompts from "../../services/prompts.json";
import { replacePrompts } from "../../services/replacePrompts";
import FeedbackDialog from "../../components/FeedbackDialog";
import { responseStore } from "../../context/responseContext";
import { QuestionStore } from "../../context/questionContext";
import { useAuthToken } from "../../hooks/useAuthToken";
import { AppRoutesProps } from "../../routes/AppRoutes";
import UpperBar from "../../components/UpperBar";

const Result: React.FC<AppRoutesProps> = ({
  hideQuestions,
  darkMode,
  setDarkMode,
}) => {
  const navigate = useNavigate();

  const [answerModel, setAnswerModel] = useState(
    "Response using model GPT - 4, the summary of your answers are the following"
  );
  const [answerByUser, setAnswerByUser] = useState("");
  const [responseInterview, setResponseInterview] = useState<string>("");
  const [congratulations, setCongratulations] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showFeedbackPromptDialog, setShowFeedbackPromptDialog] =
    useState<boolean>(false);
  const [finalPrompt, setFinalPrompt] = useState<string>("");
  const [messageError, setMessageError] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState<number | null>(null);
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";
  const { t } = useTranslation();
  const accessToken = useAuthToken();
  const {
    interview,
    currentQuestion,
    currentQuestionIndex,
    sessionId,
    questions,
    setCurrentQuestionIndex,
    setCurrentQuestion,
    completedMessage,
    completedTitle,
  } = useContext(QuestionStore);

  const [step, setStep] = useState<"summary" | "rating" | "thankYou">(
    "summary"
  );

  const { responses } = useContext(responseStore);

  const hasGeneratedRef = useRef(false);

  const [userFeedback, setUserFeedback] = useState<string>("");

  const regenerateResponse = (newFeedback?: string) => {
    setShowLoader(true);
    setResponseInterview(""); 
    setIsStreaming(true);

    // Usamos el nuevo feedback si está disponible, sino usamos el del estado
    const feedbackToUse = newFeedback || userFeedback;
    

    const promptWithFeedback = replacePrompts(prompts.regenerateWithUserFeedback, {
      feedback: answerByUser,
      userFeedback: feedbackToUse, 
      adminPrompt: interview?.Prompt || ""
    });

    console.log('Prompt with feedback', promptWithFeedback);
    console.log('User feedback', feedbackToUse);

    const iaGeneration = async () => {
      setShowLoader(true);
      setAnswerByUser("");
      setResponseInterview("");
      await generateInterviewGPT(
        promptWithFeedback,
        (response: any) => {
          setResponseInterview(response);
          setAnswerByUser(response);
        },
        setShowLoader
      );
      setIsStreaming(false);
      setResponseInterview((prev) => prev.replace(/[*#_]/g, ""));
      setAnswerByUser((prev) => prev.replace(/[*#_]/g, ""));
    };
    iaGeneration();
  };

  const handleClose = () => setOpen(false);

  const handleConfirm = async () => {
    try {
      const ResultUser = answerByUser !== responseInterview ? answerByUser : "";
      const ResultAi = responseInterview;
      const baseBody = {
        SessionId: sessionId,
      };

      if (invitationCode && rating !== null) {
        try {
          const response = await callAPI({
            method: "PUT",
            endpoint: `${endpoint}/public/result`,
            body: {
              ...baseBody,
              ResultAi,
              InvitationCode: invitationCode || "",
            },
          });

          await callAPI({
            method: "PUT",
            endpoint: `${endpoint}/public/session`,
            body: {
              ...baseBody,
              UserRating: rating,
              Id: sessionId,
              InvitationCode: invitationCode || "",
              Status: "Completed",
              CustomInstructions: userFeedback
            },
          });
         
          //await callAPI({
            //method: "POST",
            //endpoint: `${endpoint}/public/sessionSubmit`,
            //body: {
              //Id: sessionId,
              //InvitationCode: invitationCode || "",
              //FinalResult: ResultAi,
            //},
          //});

          if (response !== null) {
            setStep("thankYou");
            setMessageError(false);
          }
        } catch (error) {
          setMessageError(true);
        }
      }
      if (accessToken && !invitationCode && rating !== null) {
        try {
          const response = await callAPI({
            accessToken,
            method: "PUT",
            endpoint: `${endpoint}/result`,
            body: { ...baseBody, ResultAi },
          });
          await callAPI({
            accessToken,
            method: "PUT",
            endpoint: `${endpoint}/session`,
            body: { ...baseBody, Id: interview?.Id, UserRating: rating },
          });
          if (response !== null) {
            setStep("thankYou");
            setMessageError(false);
          }
        } catch (error) {
          setMessageError(true);
        }
      }

      //    ResultUser: ResultUser,

      /*  console.log("All answers saved successfully.", response); */
    } catch (error) {
      console.error("Error saving answers:", error);
    }
  };

  //useEffect(() => {
    //const callResult = async () => {
      //const result = await generateResultGPT();
      //console.log("resultpuedesi", result);
    //};
  //}, []);

  useEffect(() => {
    // Solo ejecutar cuando interview tenga datos válidos y responses exista
    if (interview?.Id && interview?.Prompt && responses && 
        Object.keys(responses).length > 0 && !hasGeneratedRef.current) {
      console.log('Generating interview with complete data', responses);
      hasGeneratedRef.current = true;
      
      const userInterviewData = questions!.map(
        (quest, index) => `\n${index + 1}-quest:${quest.QuestionText}\n ans:${responses[quest.Id]?.ResponseText}`
      );

      const prompt = replacePrompts(prompts.generateResultFeedback, {
        prompt: interview.Prompt,
        userInterviewData: userInterviewData.join(""),
      });
      setFinalPrompt(prompt);

      const openFunc = async () => {
        setShowLoader(true);
        setIsStreaming(true);
        await generateInterviewGPT(prompt,
          (response: any) => {
            setResponseInterview(response);
            setAnswerByUser(response);
          },
          setShowLoader
        );
        setIsStreaming(false);
        setResponseInterview((prev) => prev.replace(/[*#_]/g, ""));
        setAnswerByUser((prev) => prev.replace(/[*#_]/g, ""));
      };

      openFunc();
    }
  }, [interview]); 

  const goBack = () => {
    switch (step) {
      case "summary":
        navigate(-1);
        break;
      case "rating":
        setStep("summary");
        break;
      case "thankYou":
        navigate(-1);
        break;
    }
  };

  const handleSteps = () => {
    switch (step) {
      case "summary":
        return (
          <>
            <h1> {t("general.done")}</h1>
            {/* <p>{t("general.setdownload")}</p>{" "} */}
            {showLoader ? (
              <Box sx={{ display: "flex", marginBottom: 5 }}>
                <Loading
                  message={t("general.loadingmessage")}
                  size={50}
                  color="secondary"
                />
              </Box>
            ) : (
              <Box className={styles.summaryTextarea}>
                <TextField
                  value={answerByUser}
                  onChange={(e) => setAnswerByUser(e.target.value)}
                  variant="standard"
                  multiline
                  minRows={5}
                  maxRows={30}
                  fullWidth
                  disabled={isStreaming}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: '#fff',
                      padding: '10px',
                      overflow: 'visible',
                      display: 'block',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                    },
                    '& .MuiInputBase-input': {
                      overflow: 'visible',
                      maxHeight: 'none',
                      height: 'auto',
                      whiteSpace: 'pre-wrap',
                    },
                    height: 'auto',
                    width: '100%',
                    '& textarea': {
                      overflow: 'visible !important',
                      WebkitLineClamp: 'none',
                      display: 'block !important'
                    }
                  }}
                  InputProps={{
                    style: { 
                      overflow: 'visible',
                      display: 'block'
                    }
                  }}
                />
                <Button
                  onClick={() => {
                    setShowFeedbackPromptDialog(true);
                  }}
                  disabled={isStreaming}
                  disableElevation
                  className={styles.regenerateButton}
                  sx={{
                    backgroundColor: isStreaming ? 'transparent' : '#f5f5f5',
                    boxShadow: 'none',
                    margin: 0,
                    opacity: isStreaming ? 0.3 : 1,
                    '&:hover': {
                      backgroundColor: isStreaming ? 'transparent' : '#eeeeee'
                    }
                  }}
                >
                  <CachedIcon
                    sx={{
                      color: "#000",
                      border: "none",
                    }}
                  />
                </Button>
              </Box>
            )}{" "}
            <Button
              variant="contained"
              onClick={() => {
                setStep("rating");
              }}
              disabled={isStreaming}
              sx={{ width: "80%", marginTop: "auto" }}
            >
              {t("general.sendSummary")}
            </Button>
          </>
        );
      case "rating":
        return (
          <>
            <Typography variant="h6">
              {t("general.confirmSubmission")}
            </Typography>

            <Typography variant="body1" gutterBottom>
              {t("general.generationDisclaimer")}
            </Typography>
            <Box mt={2} textAlign="center">
              <Typography variant="subtitle1">
                {t("general.ratingTitle")}
              </Typography>
              <Rating
                value={rating !== null ? rating / 2 : 0}
                onChange={(_, newValue) =>
                  setRating(newValue !== null ? newValue * 2 : 1)
                }
                size="large"
                precision={0.5}
              />
            </Box>

            <Box display={"flex"} justifyContent={"space-between"} mt={2}>
              <Button onClick={handleClose} color="secondary">
                {t("general.cancel")}
              </Button>
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
                disabled={rating === null}
              >
                {t("general.confirm")}
              </Button>
            </Box>
          </>
        );
      case "thankYou":
        return (
          <>
            {" "}
            <h1>
              {completedTitle !== null ? completedTitle : t("general.thankYou")}
            </h1>{" "}
            <p>
              {completedMessage !== null
                ? completedMessage
                : t("general.thankYouMessage")}
            </p>
            <Button
              variant="contained"
              sx={{ width: "80%", marginTop: "auto" }}
            >
              OK
            </Button>
          </>
        );
    }
  };

  return (
    <>
      <UpperBar
        hideQuestions={hideQuestions}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <FeedbackDialog
        open={showFeedbackPromptDialog}
        onClose={() => {
          setShowFeedbackPromptDialog(false);
          setUserFeedback(""); 
        }}
        onConfirm={(feedback) => {
          setShowFeedbackPromptDialog(false);
          setUserFeedback(feedback);
          regenerateResponse(feedback); // Pasamos el feedback directamente
        }}
      />
      <div className={styles.container}>
        <div className={styles.container_center}>
          {messageError ? (
            <Alert severity="warning" onClose={() => setMessageError(false)}>
              {t("general.invalid")}
            </Alert>
          ) : null}
          <Button
            variant="text"
            sx={{
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              left: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ArrowBackIcon onClick={goBack} sx={{ color: "#000" }} />
          </Button>
          {handleSteps()}
        </div>
      </div>
    </>
  );
};

export default Result;
