import React, { useCallback, useContext } from "react";
import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { callAPI } from "../services/interview.service";
import { IResponse } from "../models/IQuestion";
import { QuestionStore } from "./questionContext";
import { IAnswerData } from "../models/IAnswer";
import { useAuthToken } from "../hooks/useAuthToken";

export const responseStore = createContext<{
  responses: Record<string, any>;
  loading: boolean;
  updateAnswer: (questionId: number, newAnswer: IAnswerData) => Promise<void>;
}>({
  responses: {},
  loading: false,
  updateAnswer: async () => { },
});

responseStore.displayName = "ResponseContext";

function ResponseContext({ children }: { children: React.ReactNode }) {
  const [responses, setResponses] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const accessToken = useAuthToken();

  const [searchParams] = useSearchParams();

  const invitationCode = searchParams.get("invitationCode");
  const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";
  const location = useLocation();
  const uuidMatch = location.pathname.match(/([a-f0-9\-]+)/);
  const uuid = uuidMatch ? uuidMatch[1] : "ID not found";

  const { sessionId } = useContext(QuestionStore);

  const questionNumMatch = location.pathname.match(/\/questions\/(\d+)/);
  const shouldUseAccessToken = location.pathname.includes("public");
  const questionId = questionNumMatch
    ? questionNumMatch[1]
    : "Number not found";

  useEffect(() => {
    const fetchResponsesInvitationCode = async () => {
      if (sessionId === 0) return; // Don't fetch if sessionId is not set yet

      setLoading(true);
      const response = await callAPI({
        method: "GET",
        endpoint: `${endpoint}/public/responses?InvitationCode=${invitationCode}&SessionId=${sessionId}`,
      });
      if (response) {
        const listAnswersByQuestionId: any = response.reduce(
          (acc: any, ans: any) => {
            acc[ans.InterviewQuestionId] = {
              Id: ans.Id,
              ResponseText: ans.ResponseText,
              InterviewQuestionId: ans.InterviewQuestionId,
              SessionId: ans.SessionId,
              InvitationCode: ans.InvitationCode
            };
            return acc;
          },
          {}
        );
        console.log("listAnswersByQuestionId", listAnswersByQuestionId);
        setResponses(listAnswersByQuestionId);
        setLoading(false);
      }
    };

    const fetchResponsesAccessToken = async () => {
      if (sessionId === 0) return; // Don't fetch if sessionId is not set yet

      setLoading(true);
      const response = await callAPI({
        accessToken,
        method: "GET",
        endpoint: `${endpoint}/response?Admin=false&SessionId=${sessionId}&InterviewUuid=${uuid}`,
      });
      if (response) {
        const listAnswersByQuestionId: any = response.reduce(
          (acc: any, ans: any) => {
            acc[ans.InterviewQuestionId] = {
              Id: ans.Id,
              ResponseText: ans.ResponseText,
              InterviewQuestionId: ans.InterviewQuestionId,
              SessionId: ans.SessionId,
              InvitationCode: ans.InvitationCode
            };
            return acc;
          },
          {}
        );
        console.log("listAnswersByQuestionId", listAnswersByQuestionId);
        setResponses(listAnswersByQuestionId);
        setLoading(false);
      }
    };

    if (invitationCode && uuid !== "ID not found") {
      fetchResponsesInvitationCode();
    }
    if (
      !invitationCode &&
      shouldUseAccessToken &&
      accessToken &&
      uuid !== "ID not found"
    ) {
      fetchResponsesAccessToken();
    }
  }, [sessionId, uuid, invitationCode, accessToken]);

  const updateAnswer = useCallback(
    async (questionId: number, newAnswer: IAnswerData) => {
      try {
        // Optimistic update
        setResponses((prev) => ({
          ...prev,
          [questionId]: newAnswer,
        }));

        if (invitationCode) {
          const response = callAPI({
            method: "PUT",
            endpoint: `${endpoint}/public/response`,
            body: newAnswer,
          });

          if (!response) {
            throw new Error("Failed to update response");
          }
        }
        if (accessToken) {
          const response = callAPI({
            accessToken,
            method: "PUT",
            endpoint: `${endpoint}/response`,
            body: newAnswer,
          });

          if (!response) {
            throw new Error("Failed to update response");
          }
        }
      } catch (error) {
        // Revert on error
        setResponses((prev) => {
          const updated = { ...prev };
          delete updated[questionId];
          return updated;
        });
        console.error("Error updating response:", error);
        throw error;
      }
    },
    []
  );

  return (
    <responseStore.Provider value={{ responses, loading, updateAnswer }}>
      {children}
    </responseStore.Provider>
  );
}

export default ResponseContext;
