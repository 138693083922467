import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Loading from "../../components/Loading";
import TextConverter from "../../utils/TextConverter";
import { useSearchParams } from "react-router-dom";
import { useAuthToken } from "../../hooks/useAuthToken";
import { callAPI, generateInterviewGPT } from "../../services/interview.service";
import { IInterview, IInterviewApi2, IInvitations, IQuestion } from "../../models/IQuestion";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";


function SummaryInterview() {
    const [loader, setLoader] = useState<boolean>(false);
    const [response, setResponse] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const [searchParams] = useSearchParams();
    const interviewId = searchParams.get("interviewId");
    const accessToken = useAuthToken();
    const { t } = useTranslation();
    const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";


    useEffect(() => {
        const fetchInterviews = async () => {
            if (interviewId) {
                try {
                    const options = {
                        accessToken,
                        method: "GET",
                        endpoint: `https://fa-np-tl-interviewai-staging.azurewebsites.net/api/interviews?All=yes`,
                    };
                    const response = await callAPI(options);
                    console.log("response", response);

                    if (response) {
                        const interview: IInterviewApi2 = response.find(
                            (item: any) => item.Id.toString() === interviewId
                        );

                        // const options2 = {
                        //     accessToken,
                        //     method: "GET",
                        //     endpoint: `https://fa-np-tl-interviewai-staging.azurewebsites.net/api/results?InterviewId=${interview.Id}&Admin=true`,
                        // };
                        // const response2 = await callAPI(options2);

                        const options2 = {
                            accessToken,
                            method: "GET",
                            endpoint: `${endpoint}/interview?Admin=true&Uuid=${interview.Uuid}`,
                        };
                        const response2 = await callAPI(options2);

                        console.log(interview.Questions, interview.Questions)

                        let promptText = "Generate a summary report based on the following questions and answers:\n\n";

                        response2.Sessions.forEach((session: any) => {
                            if(session.Responses && session.Responses.length>0){
                                session.Responses.forEach((response:any)=>{
                                    const question = interview.Questions.find((q: IQuestion) => q.Id === response.InterviewQuestionId);
                                    if (question) {
                                        promptText += `Question: ${question.QuestionText}\nAnswer: ${response.ResponseText}\n\n`;
                                    }
                                })
                            }
                            

                        })


                        setLoader(true);
                        setResponse(""); // Reset response before streaming starts

                        try {
                            await generateInterviewGPT(promptText, setResponse, setLoader);
                        } catch (err) {
                            console.error("Error generating response:", err);
                            setError("Failed to generate response.");
                            setLoader(false);
                        }
                    }



                } catch (error) {
                    console.error("Error fetching interviews:", error);
                }
            }
        };

        fetchInterviews();
    }, [interviewId, accessToken, endpoint]);


    const handleCopyInvitationLinkAuth = () => {
        const responsetext = `${response}`;
        navigator.clipboard.writeText(responsetext);
        console.log(`Copied to clipboard: ${responsetext}`);
    };


    const downloadInterview = () => {


        try {
            const csvData = [];


            csvData.push({
                Response: response,
            })

            const csvHeaders = [
                "Response",

            ];

            const worksheet = XLSX.utils.json_to_sheet(csvData, {
                header: csvHeaders,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "SummarizeResponse");

            const excelBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            const blob = new Blob([excelBuffer], {
                type: "application/octet-stream",
            });
            saveAs(blob, `interviews_${new Date().toISOString()}.xlsx`);
        } catch (error) {
            console.error("Error generating Excel file:", error);
        }
    };


    return (
        <Box>
            {loader ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Loading message={t("general.loadingmessage")} size={50} color="secondary" />
                </Box>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <>



                    {interviewId ? (
                        <Box sx={{ position: 'absolute', right: "50px" }}>

                            <Tooltip title="Copy text" >
                                <IconButton
                                    onClick={() => handleCopyInvitationLinkAuth()}
                                    size="small"
                                    sx={{ cursor: "pointer" }}
                                >
                                    <ContentCopyIcon fontSize="small"  />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download summarize" >
                                <IconButton 
                                onClick={downloadInterview}
                                size="small"
                                sx={{ cursor: "pointer" }}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ) : 'null'}

                    <Typography id="modal-response-title" variant="h6" component="h2">
                        {t("general.admin.summary")}
                    </Typography>
                    <Typography id="modal-response-description" sx={{ mt: 2 }}>
                        <TextConverter text={response || t("Loading")} />
                    </Typography>
                </>
            )}
        </Box>
    );
}

export default SummaryInterview;
