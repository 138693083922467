import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IQuestion } from "../../models/IQuestion";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";



import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import { Pause, PlayArrow, DeleteOutlineOutlined } from "@mui/icons-material";


import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./Questionnaire.module.scss";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import prompts from "../../services/prompts.json";
import {
  callAPI,
  generateInterviewGPT,
} from "../../services/interview.service";
import { useTranslation } from "react-i18next";
import { replacePrompts } from "../../services/replacePrompts";
import { QuestionStore } from "../../context/questionContext";
import { responseStore } from "../../context/responseContext";
import { IAnswerData } from "../../models/IAnswer";
import { useAuthToken } from "../../hooks/useAuthToken";
import { AppRoutesProps } from "../../routes/AppRoutes";
import UpperBar from "../../components/UpperBar";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import { set } from "date-fns";
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

const Questions: React.FC<AppRoutesProps> = ({
  darkMode,
  setDarkMode,
  hideQuestions,
}) => {
  const navigate = useNavigate();
  const uuidMatch = location.pathname.match(/([a-f0-9\-]+)\/questions\/\d+$/);
  const uuid = uuidMatch ? uuidMatch[1] : "ID not found";

  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const { t } = useTranslation();
  const { transcript, listening, resetTranscript, isMicrophoneAvailable } =
    useSpeechRecognition();

  const [answer, setAnswer] = useState<string>("");
  const [storedAnswers, setStoredAnswers] = useState<{ [key: number]: any }>(
    {}
  );
  const answerTextarea = useRef<HTMLDivElement>(null);
  const isSmall = useMediaQuery("(max-width:960px)");
  const [isDisabled, setIsDisabled] = useState(true);
  const [feedback, setFeedback] = useState<string>("");
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const [wasQuestionAnswered, setwasQuestionAnswered] =
    useState<boolean>(false);

  const accessToken = useAuthToken();

  const {
    currentQuestion,
    currentQuestionIndex,
    sessionId,
    questions,
    setCurrentQuestionIndex,
    setCurrentQuestion,
  } = useContext(QuestionStore);

  const { responses, updateAnswer } = useContext(responseStore);

  const createAnswer = useCallback(
    (responseText: string): IAnswerData => ({
      InvitationCode: invitationCode || "",
      SessionId: sessionId,
      ResponseText: responseText,
      InterviewQuestionId: currentQuestion?.Id || 0,
    }),
    [invitationCode, sessionId, currentQuestion?.Id]
  );

  const [hasTextChanged, setHasTextChanged] = useState<boolean>(false);
  const [previousAnswer, setPreviousAnswer] = useState<string>("");

  useEffect(() => {
  console.log("responses",responses)
    if (
      currentQuestion &&
      currentQuestion.Id !== undefined &&
      responses[currentQuestion.Id]?.ResponseText !== answer
    ) {
      const savedResponse = responses[currentQuestion.Id]?.ResponseText || "";
      setAnswer(savedResponse);
      setPreviousAnswer(savedResponse);
      setHasTextChanged(false);
    }
  }, [currentQuestion, responses]);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setAnswer(newValue);
    setHasTextChanged(newValue !== previousAnswer);
  };

  useEffect(() => {
    if (!listening && transcript !== "") {
      const newText = transcript.trim();
      setAnswer(prevAnswer => {
        if (prevAnswer.trim().endsWith(newText)) {
          return prevAnswer;
        }
        const updatedAnswer = prevAnswer.trim() + " " + newText;
        setHasTextChanged(updatedAnswer !== previousAnswer);
        return updatedAnswer;
      });
      resetTranscript();
    }
  }, [listening, transcript, previousAnswer]);

  const handleClear = () => {
    setAnswer("");
    setShowFeedback(false);
    setFeedback("");
    resetTranscript();
    setHasTextChanged(true);
  };

  const handleNextQuestion = async () => {
    if (!currentQuestion?.Id) return;
    resetTranscript();

    // Obtener la respuesta guardada para la pregunta actual
    const savedResponse = responses[currentQuestion.Id]
    console.log("savedResponse",savedResponse)
    
    // Crear el objeto de respuesta incluyendo el Id si existe una respuesta previa
    const newAnswer = {
      ...createAnswer(answer),
      ...(savedResponse?.Id && { Id: savedResponse.Id })
    };

    if (currentQuestionIndex < (questions.length || 0) - 1) {
      setFeedback("");
      setShowFeedback(false);
      if (invitationCode) {
        navigate(
          `/public/${uuid}/questions/${
            currentQuestionIndex + 2
          }?invitationCode=${invitationCode}`
        );
      } else if (accessToken) {
        navigate(`/${uuid}/questions/${currentQuestionIndex + 2}`);
      }

      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentQuestion(questions[currentQuestionIndex + 1]);
      await updateAnswer(currentQuestion.Id, newAnswer);
    } else {
      await updateAnswer(currentQuestion.Id, newAnswer);
      if (invitationCode) {
        navigate(`/public/${uuid}/Result?invitationCode=${invitationCode}`);
      } else if (accessToken) {
        navigate(`/${uuid}/Result`);
      }
    }
  };

  const goBack = () => {
    if (!currentQuestion) return;
    const savedResponse = responses[currentQuestion.Id]?.ResponseText || "";
    setAnswer(savedResponse);
    setFeedback("");
    setShowFeedback(false);
    setCurrentQuestionIndex(currentQuestionIndex - 1);
    setCurrentQuestion(questions[currentQuestionIndex - 1]);
    navigate(-1);
    const newAnswer = createAnswer(answer);
    try {
      updateAnswer(currentQuestion.Id, newAnswer);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSpeak = (text: string) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "es-419";
      window.speechSynthesis.speak(utterance);
      return null;
    } else {
      alert("Lo siento, tu navegador no soporta esta característica.");
    }
  };

  const [isLoadingFeedback, setIsLoadingFeedback] = useState<boolean>(false);

  const handleGenerateFeedback = async () => {
    if (feedback !== "") {
      setFeedback("");
    }
    setIsLoadingFeedback(true);
    try {
      await generateInterviewGPT(
        replacePrompts(prompts.generateAnswerFeedback, {
          question: currentQuestion?.QuestionText || "",
          answer,
        }),
        (response: any) => {
          setFeedback(response);
          setShowFeedback(true);
        },
        () => {}
      );
      if (feedback.includes("La pregunta fue respondida correctamente.")) {
        setwasQuestionAnswered(true);
      }
    } finally {
      setIsLoadingFeedback(false);
    }
  };

  const handleRecording = () => {
    if (!listening && isMicrophoneAvailable) {
      resetTranscript();
      SpeechRecognition.startListening({
        language: "es-CO",
        continuous: true
      });
    } else {
      SpeechRecognition.stopListening();
    }
  };

  return (
    <>
      <UpperBar
        hideQuestions={hideQuestions}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <div
        className={styles.container}
        style={isSmall ? { height: "85dvh" } : {}}
      >
        <div
          className={styles.container_center}
          style={isSmall ? { justifyContent: "space-between" } : {}}
        >
          <div
            style={{ marginBottom: "40px", marginTop: "20px", width: "100%" }}
          >
            <LinearProgress
              variant="determinate"
              value={(100 / questions.length) * (currentQuestionIndex + 1)}
            />
            <br></br>
            <Button
              variant="text"
              sx={{
                position: "absolute",
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                left: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ArrowBackIcon onClick={goBack} sx={{ color: "#000" }} />
            </Button>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("general.question")} {currentQuestionIndex + 1}
            </Typography>
            <p style={{ margin: 0 }}>
              {currentQuestionIndex + 1}
              {t("general.progress")}
              {questions.length}
            </p>

            <h2>
              {currentQuestion?.QuestionText}{" "}
              <IconButton
                onClick={() => handleSpeak(currentQuestion?.QuestionText || "")}
              >
                <HeadphonesOutlinedIcon />{" "}
              </IconButton>
            </h2>
          {/* <p>{t("general.useMic")}</p> */}
            <Box
              display={"flex"}
              //justifyContent={"space-between"}
              margin={"0 auto"}
              maxWidth={"400px"}
              width={"80%"}
            >
              <Button
                variant="text"
                sx={{ width: "70%", borderRadius: "20px" }}
                className={
                  listening
                    ? styles.button_stoprecording
                    : styles.button_recording
                }
                onClick={handleRecording}
                startIcon={
                  listening ? (
                    <Pause className={styles.icon_button} />
                  ) : (
                    <PlayArrow className={styles.icon_button} />
                  )
                }
              >
                {listening ? t("general.recording") : t("general.capture")}
              </Button>
              <IconButton onClick={handleClear}>
                <DeleteOutlineOutlined />
              </IconButton>
            </Box>
            <br></br>
            <TextField
              value={answer}
              onChange={handleAnswerChange}
              variant="outlined"
              multiline
              minRows={5}
              maxRows={12}
              inputProps={{ cols: 40 }}
              fullWidth
              inputRef={answerTextarea}
              sx={{ 
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
            />
            
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                mt: 1,
                ml: 1
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={handleGenerateFeedback}
                disabled={isLoadingFeedback || answer.trim().length === 0 || !hasTextChanged}
                sx={{ 
                  minWidth: 'unset',
                  width: '36px',
                  height: '36px',
                  padding: '0 !important',
                  borderRadius: '6px',
                  backgroundColor: '#2196f3',
                  '&:hover': {
                    backgroundColor: '#1976d2'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: '#90caf9',
                    opacity: 0.3
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  lineHeight: 1,
                  '& .MuiButton-startIcon': {
                    margin: 0
                  }
                }}
              >
                {isLoadingFeedback ? (
                  <CircularProgress size={22} sx={{ color: 'white' }} />
                ) : (
                  <RateReviewOutlinedIcon sx={{ 
                    fontSize: 22, 
                    color: 'white',
                    margin: 0,
                    padding: 0
                  }} />
                )}
              </Button>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ fontSize: '0.75rem' }}
              >
                {t("general.getFeedback")}
              </Typography>
            </Box>
          </div>

          <Button
            variant="contained"
            onClick={handleNextQuestion}
            disabled={
              currentQuestion?.IsRequired
                ? answer.length > 0
                  ? false
                  : true
                : false
            }
            sx={{
              width: "90%",
              maxWidth: "400px",
              backgroundColor: isDisabled ? "lightgray" : "primary.main",
              "&:hover": {
                backgroundColor: isDisabled ? "lightgray" : "primary.dark",
              },
            }}
          >
            {t(
              `general.${
                currentQuestionIndex < (questions.length || 0) - 1
                  ? "next"
                  : "generate"
              }`
            )}
          </Button>
          <FeedbackSlider
            isVisible={showFeedback}
            feedback={feedback}
            onNext={handleNextQuestion}
            onEdit={() => {
              if (answerTextarea.current) {
                answerTextarea.current.focus();
              }
              setShowFeedback(false);
            }}
            onClose={() => setShowFeedback(false)}
          />
        </div>
      </div>
    </>
  );
};

export default Questions;
