// src/routes/AppRoutes.tsx

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Questions from "../pages/Questionnaire/Questionnaire";
import Result from "../pages/Result/Result";
import PreQuestionnaire from "../pages/PreQuestionnaire/PreQuestionnaire";
import UpperBar from "../components/UpperBar";
import BottomBar from "../components/BottomBar";
import FetchUuid from "../components/FetchUuid";
import ProtectedRoute from "../components/ProtectedRoute";
import NoPage from "../pages/NogPage/NoPage";
import DashboardAdmin from "../pages/Dashboard/DashboardAdmin";
import Login from "../pages/Login/LoginMenu";
import CreateInterview from "../pages/CreateInterview/CreateInterview";
import NotFound from "../pages/NogPage/NoPage";
import ChatInterview from "../pages/ChatInterview/ChatInterview";
import QuestionContext from "../context/questionContext";
import ResponseContext from "../context/responseContext";
import DrawerWrapper from "../components/DrawerWrapper/DrawerWrapper";
import { useMsal } from "@azure/msal-react";
import LoginMenu from "../pages/Login/LoginMenu";
import ResultInterview from "../pages/Resultinterview/Resultinterview";

export interface AppRoutesProps {
  hideQuestions: boolean;
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface HomeRoutesProps {
  hideQuestions: boolean;
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  publicMode: boolean;
}

const AppRoutes: React.FC<AppRoutesProps> = ({
  hideQuestions,
  darkMode,
  setDarkMode,
}) => {
  const { accounts } = useMsal();
  const shouldHideBottomBar =
    location.pathname.includes("result") ||
    location.pathname.includes("questions");
  return (
    <Router>
      <QuestionContext>
        <ResponseContext>
          <Routes>
            <React.Fragment></React.Fragment>
            {/*  <Route path="" element={<NoPage />} /> */}
            <Route
              path="/public/:uuid/*"
              element={
                <FetchUuid>
                  <Routes>
                    <Route
                      path=""
                      element={
                        <Home
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                          publicMode={true}
                        />
                      }
                    />
                    <Route
                      path="introduction"
                      element={
                        <PreQuestionnaire
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      }
                    />
                    <Route
                      path="questions/:questionId"
                      element={
                        <Questions
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      }
                    />
                    <Route
                      path="result"
                      element={
                        <Result
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      }
                    />
                    <Route path="notfound" element={<NotFound />} />
                  </Routes>
                </FetchUuid>
              }
            />
            <Route
              path="/:uuid/*"
              element={
                <FetchUuid>
                  <Routes>
                    <Route
                      path=""
                      element={
                        <Home
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                          publicMode={false}
                        />
                      }
                    />
                    <Route
                      path="introduction"
                      element={
                        <PreQuestionnaire
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      }
                    />
                    <Route
                      path="questions/:questionId"
                      element={
                        <Questions
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      }
                    />
                    <Route
                      path="result"
                      element={
                        <Result
                          hideQuestions={hideQuestions}
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      }
                    />
                    <Route path="notfound" element={<NotFound />} />
                  </Routes>
                </FetchUuid>
              }
            />
            <Route path="" element={<LoginMenu />} />
            <Route element={<ProtectedRoute />}>
              <Route path="my-dashboard" element={<DashboardAdmin />} />
              <Route path="create-edit-interview" element={<CreateInterview />} />
              <Route path="results-interview" element={<ResultInterview />} />
              <Route
                path="create-edit-interview"
                element={<CreateInterview />}
              />
              <Route path="chat-interview" element={<ChatInterview />} />
            </Route>
            <Route path="notfound" element={<NotFound />} />
          </Routes>
          {/* <BottomBar /> */}
          {/*shouldHideBottomBar ? <BottomBar /> : null*/}
        </ResponseContext>
      </QuestionContext>
    </Router>
  );
};

export default AppRoutes;
