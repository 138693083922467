import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { callAPI } from "../services/interview.service";
import { QuestionStore } from "../context/questionContext";
import { useSearchParams } from "react-router-dom";

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (feedback: string) => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState<string>("");
  const { sessionId } = useContext(QuestionStore);
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";

  useEffect(() => {
    const fetchCustomInstructions = async () => {
      if (invitationCode && sessionId) {
        try {
          const response = await callAPI({
            method: "GET",
            endpoint: `${endpoint}/public/session?InvitationCode=${invitationCode}&Id=${sessionId}`,
          });
          
          if (response?.CustomInstructions) {
            setFeedback(response.CustomInstructions);
          }
        } catch (error) {
          console.error('Error fetching custom instructions:', error);
        }
      }
    };

    fetchCustomInstructions();
  }, [invitationCode, sessionId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" textAlign="center">
        {t("general.regenerateModalTitle")}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          variant="outlined"
          multiline
          minRows={3}
          maxRows={7}
          inputProps={{ cols: 40 }}
          fullWidth></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t("general.cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm(feedback);
          }}
          color="secondary"
          variant="outlined"
          autoFocus>
          {t("general.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
