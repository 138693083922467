import {
  AppBar,
  Divider,
  Drawer,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { theme } from "../theme";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useSearchParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppDispatch, RootState } from "../store/store";
import { fetchQuestions } from "../store/slices/questionsSlice";
import { IUpperBar } from "../models/IUpperBar";
import ToggleButtonDark from "./ToggleButtonDark";
import SignOutButton from "../components/SignOutButton";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IInterviewState } from "../models/IInterviewState";
import { IQuestion, IResponse } from "../models/IQuestion";
import ChangeLanguage from "./ChangeLanguage";
import { QuestionStore } from "../context/questionContext";
import { responseStore } from "../context/responseContext";

function UpperBar(props: IUpperBar) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { interview, questions } = useContext(QuestionStore);
  const { responses } = useContext(responseStore);
  const { data, loading, error } = useSelector(
    (state: { interview: IInterviewState }) => state.interview
  );
  const answers = useSelector(
    (state: RootState) => state.interviewAnswer.answers
  );
  const [isAnswered, setIsAnswered] = useState(false);
  const { darkMode, setDarkMode, hideQuestions } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const uuid = useSelector((state: RootState) => state.uuidData.uuid);

  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");

  const drawerWidth = 240;

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const DrawerHeader = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  }));

  const goQuestion = (id: number, question: IQuestion) => {
    navigate(`${uuid}/questions/${id}?invitationCode=${invitationCode}`);
    setIsAnswered(answers[question.Id] !== "");
  };

  const handleSettingsClick = () => {
    // Handle settings click
    console.log("Settings clicked");
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          fontSize: "14px",
          "@media (min-width: 769px)": {
            fontSize: "16px",
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ marginRight: "20px" }}
          >
            {uuid && <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <a
              href={`/${uuid}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Interview GPT
            </a>
          </Typography>
          {/* <ToggleButtonDark
            darkMode={darkMode}
            handleThemeChange={handleDarkMode}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "row-reverse",
            }}
          >
            {!uuid && <SignOutButton />}
            <ChangeLanguage />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List style={{ flex: 1, overflowY: "auto" }}>
          {questions.map((question, index) => (
            <ListItem key={question.Id} disablePadding>
              <ListItemButton
                onClick={() => goQuestion(question.QuestionOrder, question)}
              >
                <ListItemText primary={question.QuestionText} />
                <ListItemIcon>
                  {responses[question.Id]?.ResponseText !== "" ? (
                    <CheckCircleOutlineIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List>
          <ListItem disablePadding style={{ borderTop: "1px solid #ccc" }}>
            <ListItemButton onClick={handleSettingsClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default UpperBar;
