import { Box, Button, SvgIcon, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./PreQuestionnaire.module.scss";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import { loginRequest } from "../../authConfig";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useDispatch } from "react-redux";
import { fetchQuestions } from "../../store/slices/questionsSlice";
import { IInterviewState } from "../../models/IInterviewState";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppRoutesProps } from "../../routes/AppRoutes";
import UpperBar from "../../components/UpperBar";
import FeedbackIcon from "../../assets/FeedbackIcon";
import { QuestionStore } from "../../context/questionContext";

const PreQuestionnaire: React.FC<AppRoutesProps> = ({
  hideQuestions,
  darkMode,
  setDarkMode,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");
  const accessToken = useAuthToken();
  const uuidMatch = location.pathname.match(/([a-f0-9\-]+)/);
  const uuid = uuidMatch ? uuidMatch[1] : "ID not found";
  const { t } = useTranslation();
  const goBack = () => {
    if (step === "instructions") {
      setStep("welcome");
    } else {
      navigate(-1);
    }
  };
  const [step, setStep] = useState<"welcome" | "instructions">("welcome");

  const dispatch = useDispatch();

  const { welcomeMessage, welcomeTitle } = useContext(QuestionStore);

  const startInterview = () => {
    if (uuid !== null && invitationCode !== null) {
      navigate(`/public/${uuid}/questions/1?invitationCode=${invitationCode}`);
      /* dispatch(fetchQuestions({ uuid, invitationCode })) */
    } else if (uuid !== null && accessToken) {
      navigate(`/${uuid}/questions/1`);
    }
  };

  return (
    <>
      <UpperBar
        hideQuestions={hideQuestions}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <div className={styles.container}>
        <div className={styles.container_center}>
          <Button
            variant="text"
            sx={{
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              left: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {/* <ArrowBackIcon onClick={goBack} sx={{ color: "#000" }} /> */}
          </Button>
          {step === "welcome" ? (
            <>
              <h2>
                {welcomeTitle !== null ? welcomeTitle : t("general.welcome")}
              </h2>
              <p>
                {welcomeMessage !== null
                  ? welcomeMessage
                  : t("general.welcomeText")}
              </p>
              <Button
                variant="contained"
                sx={{
                  width: "60%",
                  borderRadius: "20px",
                  padding: "15px 0px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
                onClick={() => {
                  setStep("instructions");
                }}
              >
                <Typography variant="body2">
                  {t("general.startInterview")}
                </Typography>
              </Button>
            </>
          ) : (
            <>
              <h2>{t("general.instructions")}</h2>
              <p style={{ textAlign:"start" }}>
                {t("general.instructionsThankYou")}<br></br>
                {t("general.instructionsDataUsage")}<br></br><br></br>
                {t("general.instructionsAudio")}<br></br>
                {t("general.instructionsMic")}<br></br><br></br>
                {t("general.instructionsFeedback")}
              </p>

              <Button
                variant="contained"
                sx={{
                  width: "60%",
                  borderRadius: "20px",
                  padding: "15px 0px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
                onClick={startInterview}
              >
                <CheckIcon fontSize="small" />{" "}
                <Typography variant="body2">{t("general.ready")}</Typography>
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PreQuestionnaire;
