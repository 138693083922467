import React, { FC, ReactNode, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import styles from "./DrawerWrapper.module.scss";
import MuiDrawer from "@mui/material/Drawer";
import { Avatar, Button, IconButton } from "@mui/material";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HomeIcon from "@mui/icons-material/Home";
import { useMsal } from "@azure/msal-react";
import { getInitials, getFirstAndLastName } from "../../utils/authUtils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ViewSidebarRoundedIcon from '@mui/icons-material/ViewSidebarRounded';

const DrawerWrapper: FC<{ open: boolean; setOpen: (arg: boolean) => void }> = ({
  open,
  setOpen,
}) => {
  const drawerWidth = "20%";
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    display: "flex",
    padding: "30px 15px",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "space-between",
    height: "94%",
    "& div": {
      zIndex: 1,
    },
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "5% !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    padding: "30px 15px",
    height: "94%",
    "& div": {
      zIndex: 1,
    },
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100%",
    boxSizing: "border-box",
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        },
      },
    ],
  }));
  const { accounts } = useMsal();
  const name = getInitials(accounts[0]);
  const fullName = getFirstAndLastName(accounts[0]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <section
        className={styles.headerSection}
        style={{ alignItems: open ? "flex-start" : "center" }}
      >
        <div className={styles.drawerHeader}>
          <Avatar sx={{ bgcolor: "#28496b" }}>{name}</Avatar>
          {open ? (
            <section>
              <h3>{fullName}</h3>
              <p style={{color:'#28496b'}}>{t("general.admin.admin")}</p>
            </section>
          ) : null}
        </div>

        <button
          className={styles.button}
          onClick={() => {
            navigate("/my-dashboard");
          }}
        >
          <HomeIcon />
          {open ? <p> {t("general.admin.home")} </p> : null}
        </button>
        <button
          className={styles.button}
          onClick={() => {
            navigate("/create-edit-interview");
          }}
        >
          <FactCheckIcon />
          {open ? <p>{t("general.admin.createInterview")} </p> : null}
        </button>
      </section>

      <button
        className={styles.button}
        style={{ justifyContent: open ? "space-between" : "center" }}
        onClick={toggleDrawer}
      >
        {open ? <p>Interview GPT</p> : null}
        <ViewSidebarRoundedIcon />
      </button>
    </Drawer>
  );
};

export default DrawerWrapper;
