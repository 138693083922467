import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, msalInstance } from "../authConfig";

export const acquireToken = async (account: AccountInfo | null) => {
  if (!account) {
    throw new Error("No account information found.");
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup({
        ...loginRequest,
        account: account,
      });
      return response.accessToken;
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getFirstName = (account: AccountInfo | null) => {
  if (!account) {
    return "";
  }
  if (account.name?.includes(",")) {
    return account.name?.split(", ")[1].split(" ")[0];
  } else {
    return account.name?.split(" ")[0];
  }
};
export const getFirstAndLastName = (account: AccountInfo | null) => {
  if (!account || !account.name) {
    return "";
  }
  if (account.name.includes(",")) {
    const name = account.name.split(", ");
    return name[1].split(" ")[0] + " " + name[0].split(" ")[0];
  } else {
    return account.name;
  }
};

export const getInitials = (account: AccountInfo | null) => {
  if (!account || !account.name) {
    return "";
  }
  console.log(account.name);
  if (account.name.includes(",")) {
    const name = account.name?.split(", ").reverse();
    return name[0][0] + name[name.length - 1][0];
  } else {
    const name = account.name.split(" ");
    return name[0][0].toUpperCase() + name[0].slice(-1).toUpperCase();
  }
};
