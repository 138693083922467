export const replacePrompts = (
  text: string,
  replaceTexts: { [key: string]: string }
) => {
  Object.entries(replaceTexts).forEach(([key, value]) => {
    text = text.replace(new RegExp(`{${key}}`, "g"), value);
  });
  console.log(text);
  return text;
};
