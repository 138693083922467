import React, { useState, useEffect } from "react";
import styles from "./DashboardAdmin.module.scss";
import TableInterview from "../../components/TableInterview";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { callAPI } from "../../services/interview.service";
import { useAuthToken } from "../../hooks/useAuthToken";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import DrawerWrapper from "../../components/DrawerWrapper/DrawerWrapper";
import { getFirstName } from "../../utils/authUtils";
import { useMsal } from "@azure/msal-react";

function DashboardAdmin() {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateTable, setUpdateTable] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [totalInterviews, setTotalInterviews] = useState<any>([]);
  

  const accessToken = useAuthToken();
  const { accounts } = useMsal();
  const firstName = getFirstName(accounts[0]);
const endpoint="https://fa-np-tl-interviewai-staging.azurewebsites.net/api"
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const fetchInterviews = async () => {
        try {
            const totalInterviews: any[] = []; // Ensure it's an array

            const options = {
                accessToken,
                endpoint: `${endpoint}/interviews?All=yes`,
                method: "GET",
            };

            if (accessToken || updateTable) {
                setLoading(true);
                const response = await callAPI(options);
                setInterviews(response)
                
                if (response) {
                    const uuidInterviews = response.map((resp: any) => resp.Uuid);

                    // Use for...of to await each API call sequentially
                    for (const e of uuidInterviews) {
                        const options2 = {
                            accessToken,
                            method: "GET",
                            endpoint: `${endpoint}/interview?Admin=true&Uuid=${e}`,
                        };
                        const response2 = await callAPI(options2);
                        totalInterviews.push(response2);
                    }

                    console.log("Total Interviews:", totalInterviews);
                    setTotalInterviews(totalInterviews)
                    //; // Store fetched interviews
                    setLoading(false);
                    setUpdateTable(false);
                }
            }
        } catch (err) {
            console.error("Error getting interviews:", err);
            setUpdateTable(false);
        }
    };

    fetchInterviews();
}, [accessToken, updateTable]);

  const createNewInterview = () => {
    navigate("/create-edit-interview");
  };

  if (!accessToken) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      <DrawerWrapper open={open} setOpen={setOpen} />
      <div style={{ width: open ? "80%" : "95%" }} className={styles.container}>
        <div className={styles.summary}>
          <div className={styles.summarygroup}>
            <div className={styles.summarycount}>
              <Typography variant="h2" sx={{ mb: "15px" }}>
                {`${t("general.admin.welcome")}${firstName}`}{" "}
              </Typography>
              <Typography variant="subtitle1">
                {t("general.admin.start")}
              </Typography>
            </div>
            <div className={styles.createInterview}>
              <Typography variant="h2">
                {t("general.admin.homeCreateTitle")}
              </Typography>
              <p>{t("general.admin.createDescription")}</p>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "25px 9px" }}
                onClick={createNewInterview}
              >
                {t("general.admin.createButton")}
              </Button>
            </div>
          </div>
          <br />
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Loading
                message={t("general.loadingmessage")}
                size={50}
                color="secondary"
              />{" "}
            </Box>
          )}
          {!loading && interviews.length > 0 ? (
            <TableInterview
              interviews={totalInterviews}
              setUpdateTable={setUpdateTable}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default DashboardAdmin;
