import React, { useEffect } from "react";
import HeadTableComponent from "./HeadTableComponent";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CircularProgress,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AssistantIcon from "@mui/icons-material/Assistant";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IInterviewItem } from "../models/IInterviewTable";
import { format } from "date-fns";
import { callAPI } from "../services/interview.service";
import { useAuthToken } from "../hooks/useAuthToken";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { generateInterviewGPT } from "../services/interview.service";
import { useTranslation } from "react-i18next";
import TextConverter from "../utils/TextConverter";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ConfirmationDialog from "./ConfirmationDialog";
import Loading from "./Loading";
import ResultInterview from "../pages/Resultinterview/Resultinterview";

type Order = "asc" | "desc";

interface TableInterviewProps {
  interviews: any[];
  setUpdateTable: any;
}

function TableInterview({ interviews, setUpdateTable }: TableInterviewProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof IInterviewItem>("Id");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedUuid, setSelectedUuid] = React.useState<string | null>(null);
  const [response, setResponse] = React.useState<string | null>(null);
  const [loadertable, setLoadertable] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [qtyResults, setQtyResults] = React.useState<{ [key: string]: any }>({});

  const [selectedInterviewId, setSelectedInterviewId] = React.useState<
    number | null
  >(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const accessToken = useAuthToken();
  console.log("accessToken", accessToken)
  const navigate = useNavigate();
  const endpoint = "https://fa-np-tl-interviewai-staging.azurewebsites.net/api";

  const { t } = useTranslation();
  interface EnhancedTableToolbarProps {
    numSelected: number;
  }


  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    const handleEdit = () => {
      if (selectedInterviewId) {
        navigate(`/create-edit-interview?interviewId=${selectedInterviewId}`);
      }
    };



    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>
          <Typography
            sx={{ flex: "1 1 100%", fontWeight: "bold" }}
            color="inherit"
            variant="h6"
            component="div"
          >
            Interviews
          </Typography>
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} {t("general.admin.selected")}
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              id="tableTitle"
              component="div"
            ></Typography>
          )}
        </div>
        {numSelected === 1 ? (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit">
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleOpenDialog}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download interview(s)">
              <IconButton onClick={downloadInterview}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Generate summary of user responses">
              <IconButton onClick={handleGenerateResponsesSummary}>
                <AssistantIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Open Chat">
              <IconButton onClick={handleOpenChat}>
                <ChatOutlinedIcon />
              </IconButton>
            </Tooltip> */}
          </div>
        ) : (
          numSelected > 1 && (
            <Tooltip title="Download interview(s)">
              <IconButton onClick={downloadInterview}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )
        )}
      </Toolbar>
    );
  }

  const downloadInterview = async () => {
    const selectedInterviews = interviews.filter((interview) =>
      selected.includes(interview.Id)
    );

    if (selectedInterviews.length === 0) {
      console.error("No interviews selected for download");
      return;
    }



    try {
      const csvData = [];
      const results = []

      for (const interview of selectedInterviews) {
        console.log("interview", interview);

        const options = {
          accessToken,
          endpoint: `https://fa-np-tl-interviewai-staging.azurewebsites.net/api/results?InterviewId=${interview.Id}&Admin=true`,
          method: "GET",
        };


        const res = await callAPI(options);





        for (const session of interview.Sessions || []) {
          for (const question of interview.Questions || []) {


            const responses = session.Responses.find((q: any) => q.InterviewQuestionId === question.Id);
            const resultai = res.find((q: any) => q.SessionId === session.Id);


            //const result = invitation.Results?.[0];

            csvData.push({
              InterviewId: interview.Id,
              Title: interview.Title,
              Description: interview.Description,
              CreatedAt: interview.CreatedAt,
              Prompt: interview.Prompt,
              Model: interview.Model,
              Uuid: interview.Uuid,
              Status: interview.Status, 
              //Email: invitation.Email,
              Question: question.QuestionText,
              Session: session.Id,
              SessionUser: session.SessionUser,
              Response: responses ? responses.ResponseText : "",

              ResultAi: resultai ? resultai.ResultAi : "",
              //ResultUser: result ? result.ResultUser : "",
              ResultCreatedAt: responses ? responses.CreatedAt : "",
              ResultUpdatedAt: responses ? responses.UpdatedAt : "",
            });
          }



        }
      }

      const csvHeaders = [
        "InterviewId",
        "Title",
        "Description",
        "CreatedAt",
        "Prompt",
        "Model",
        "Uuid",
        "Status",
        "Question",
        "Response",
        "ResultAi",
        "ResultCreatedAt",
        "ResultUpdatedAt",
      ];

      const worksheet = XLSX.utils.json_to_sheet(csvData, {
        header: csvHeaders,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Interviews");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `interviews_${new Date().toISOString()}.xlsx`);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };



  const deleteInterview = async () => {
    if (!selectedUuid) {
      console.error("No interview selected for deletion");
      return;
    }

    const interview = interviews.find(
      (interview) => interview.Uuid === selectedUuid
    );

    if (!interview) {
      console.error("Interview not found");
      return;
    }
    const { Uuid, Questions, Invitations } = interview;

    try {
      if (Invitations && Invitations.length > 0) {
        for (const invitation of Invitations || []) {

          if (invitation.Responses && invitation.Responses.length > 0) {
            for (const response of invitation.Responses || []) {
              try {
                const deleteResponse = await callAPI({
                  accessToken,
                  method: "DELETE",
                  endpoint: `${endpoint}/public/response`,
                  body: { Id: response.Id },
                });

                console.log("deleteResponse", deleteResponse);
              } catch (error) {
                console.error(
                  `Failed to delete response with Id ${response.Id}:`,
                  error
                );
              }
            }
          } else {
            console.log("No responses to delete for this invitation");
          }

          if (invitation.Results && invitation.Results.length > 0) {
            for (const result of invitation.Results || []) {
              try {
                const deleteresult = await callAPI({
                  accessToken,
                  method: "DELETE",
                  endpoint: `${endpoint}/public/result`,
                  body: {
                    Id: result.Id,
                    InvitationCode: invitation.InvitationCode,
                  },
                });
                console.log("deleteresult", deleteresult);
              } catch (error) {
                console.error(
                  `Failed to delete result with Id ${result.Id}:`,
                  error
                );
              }
            }
          } else {
            console.log("No results to delete for this invitation");
          }

          try {
            const deleteinvitation = await callAPI({
              accessToken,
              method: "DELETE",
              endpoint: `${endpoint}/invitation`,
              body: { Id: invitation.Id },
            });
            console.log("deleteinvitation", deleteinvitation);
          } catch (error) {
            console.error(
              `Failed to delete invitation with Id ${invitation.Id}:`,
              error
            );
          }
        }
      } else {
        console.log("No invitations to delete");
      }

      if (Questions && Questions.length > 0) {
        for (const question of Questions || []) {
          try {
            const deleteQuestion = await callAPI({
              accessToken,
              method: "DELETE",
              endpoint: `${endpoint}/question`,
              body: { Id: question.Id },
            });
            console.log("deleteQuestion", deleteQuestion);
          } catch (error) {
            console.error(
              `Failed to delete question with Id ${question.Id}:`,
              error
            );
          }
        }
      } else {
        console.log("No questions to delete");
      }

      try {
        const deleteInterview = await callAPI({
          accessToken,
          method: "DELETE",
          endpoint: `${endpoint}/interview`,
          body: { Uuid },
        });
        console.log("deleteInterview", deleteInterview);

        console.log(
          `Interview ${interview.Title} and all related data deleted successfully`
        );

        setUpdateTable(true);

        navigate("/my-dashboard");
      } catch (error) {
        console.error(`Failed to delete interview with Uuid ${Uuid}:`, error);
      }
    } catch (error) {
      console.error("Error deleting interview:", error);
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IInterviewItem
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = interviews.map((n) => n.Id);
      setSelected(newSelected);
      setSelectedUuid(null);
      return;
    }
    setSelected([]);
    setSelectedUuid(null);
  };

  function stableSort<T>(array: any, comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map(
      (el: any, index: any) => [el, index] as [T, number]
    );
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number,
    uuid: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      setSelectedUuid(uuid);
      setSelectedInterviewId(id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setSelectedUuid(null);
      setSelectedInterviewId(null);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      setSelectedUuid(null);
      setSelectedInterviewId(null);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      setSelectedUuid(null);
      setSelectedInterviewId(null);
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - interviews.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        interviews.map((interview) => ({
          ...interview,
          Questions: interview.Questions ? interview.Questions.join(", ") : "",
          InvitationsEmails: interview.Invitations
            ? interview.Invitations.map((invite: any) => invite.Email).join(", ")
            : "",
          InvitationsCodes: interview.Invitations
            ? interview.Invitations.map((invite: any) => ({
              email: invite.Email,
              code: invite.InvitationCode,
            }))
            : [],
        })),
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, interviews]
  );

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  //confirmation dialog

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmAction = async () => {
    setOpenDialog(false);
    setLoadertable(true);
    await deleteInterview();
    setLoadertable(false);
    console.log("Interview eliminated!");
  };

  const handleRowClick = (id: number) => {
    navigate(`/create-edit-interview?interviewId=${id}`);
  };
  const goResults = (id: number) => {
    navigate(`/results-interview?interviewId=${id}`);
  }

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const newResults: { [key: string]: any } = {};
        for (const row of visibleRows) {
         

          // if (response) {

          //   newResults[row.Id] = response.length
          // }

          const optionInterview = {
            accessToken,
            method: "GET",
            endpoint: `${endpoint}/interview?Admin=true&Uuid=${row.Uuid}`
          }
          const onlyInterview = await callAPI(optionInterview);
          const userCount = new Set(
            onlyInterview.Sessions
                .filter((session:any) => session.SessionUser) // Ensure the session has a user
                .map((session:any) => session.SessionId) // Extract unique users
        ).size;
        newResults[row.Id] = userCount
        console.log("newResults", newResults)
        
        setQtyResults(newResults)
      
        }
        

      
      }
      catch (e) {
        console.log(e)
      }



    }
    fetchResults()



  }, [visibleRows, ResultInterview, accessToken, interviews])



  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          borderRadius: "15px !important",
          pt: "20px",
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <HeadTableComponent
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={interviews.length}
            />

            <TableBody>
              {visibleRows.map((row: any, index: any) => {
                const isItemSelected = isSelected(row.Id);
                const labelId = `enhanced-table-checkbox-${index}`;
                {
                  row.Invitations && row.Invitations.map((invitation: any) => {
                    const responses = invitation.Responses || [];
             
                  })
                }

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.Id, row.Uuid)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.Id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRowClick(row.Id);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {row.Title}
                    </TableCell>
                    <TableCell align="left">{row.Status}</TableCell>
                    <TableCell align="left">
                      {format(new Date(row.CreatedAt), "dd-MM-yyyy")}
                    </TableCell>

                    {/* <TableCell align="left" padding="none" scope="row" style={{ width: "20px" }}>
                     
                      {qtyResults !== undefined ? `${qtyResults[row.Id]}/${row.Invitations.length} ` : 'no data'}
                    </TableCell> */}

                    <TableCell align="left" style={{ width: "180px" }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none">
                      <Button style={{ padding: "10px", fontSize: "14px", margin: "1px", width: "80%" }} variant="contained" color="primary"
                        onClick={(e) => {

                          e.stopPropagation();
                          goResults(row.Id)
                        }

                        }
                      >Result</Button></TableCell>

                    {/* <TableCell align="right">
                      <LinearProgress
                        variant="determinate"
                        value={row.progress}
                      />
                    </TableCell> */}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 43,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={t("general.admin.rowpp")}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={interviews.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>


      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
        message={t("general.admin.deleteInterview")}
      />
    </Box>
  );
}

export default TableInterview;
