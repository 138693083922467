import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';

import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    message,
}) => {

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" textAlign="center"> {t("general.admin.sure")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent:"center"}}>
                <Button onClick={onClose}  color="primary"  variant="contained" style={{padding:"7px 30px"}}>
                {t("general.cancel")}
                </Button>
                <Button onClick={onConfirm} color="secondary"  variant="outlined" autoFocus style={{padding:"7px 30px"}}>
                {t("general.accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
