import React from "react";
import { Slide, Box, Typography, Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import FeedbackIcon from "../../assets/FeedbackIcon";
import CloseIcon from '@mui/icons-material/Close';

interface FeedbackSliderProps {
  isVisible: boolean;
  feedback: string;
  onNext: () => void;
  onEdit: () => void;
  onClose?: () => void;
}

const FeedbackSlider: React.FC<FeedbackSliderProps> = ({
  isVisible,
  feedback,
  onNext,
  onEdit,
  onClose
}) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        bgcolor="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="15px"
        p="20px"
        border="1px solid #A6C2E2"
        borderRadius="17px 17px 0 0"
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography 
          variant="body2" 
          sx={{ 
            alignSelf: "start", 
            display: "flex", 
            alignItems: "center",
            width: "85%"
          }}
        >
          <FeedbackIcon /> 
          <span style={{paddingLeft: "10px"}}>Feedback</span>
        </Typography>

        <Typography
          variant="body2"
          sx={{ 
            color: "#7FA2D5", 
            textAlign: "left", 
            width: "85%",
            pr: 4 // Espacio para el botón de cerrar
          }}
        >
          {feedback}
        </Typography>

        <Button
          sx={{
            width: "85%",
            borderRadius: "12px",
            color: "#77A2D3",
            border: "1px solid #78A2D3",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={onEdit}
        >
          Modify Answer
        </Button>

        <Button
          variant="contained"
          sx={{
            width: "85%",
            borderRadius: "12px",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={onNext}
        >
          Next
        </Button>
      </Box>
    </Slide>
  );
};

export default FeedbackSlider;
