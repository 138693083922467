import React, { useEffect, useState } from "react";
import { marked } from "marked";

interface TextConverterProps {
  text: any;
}

const TextConverter: React.FC<TextConverterProps> = ({ text }) => {
  const [htmlContent, setHtmlContent] = useState<string>("");

  useEffect(() => {
    const convertTextToHtml = async () => {
      const convertedHtml = await Promise.resolve(marked(text));
      setHtmlContent(convertedHtml);
    };

    convertTextToHtml();
  }, [text]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default TextConverter;
